import React, { useState } from 'react';
import Menu from '../../component/Menu';
import Finetuning from '../../component/Finetuning/Finetuning';

const Finetune = () => {

    return (
        <>
            <Menu logic={<Finetuning />} page={"Finetuning"} description={"Finetuning portal for building datasets to optimize our AI models."} />
        </>
    );
};

export default Finetune;
