import React, { createContext, useState, useEffect, useContext } from 'react'
import apiClient from './APIClient.js'
import { useAuth } from './AuthProvider.js'
import { TEST } from './TestData.js'

const CustomerContext = createContext()

export function useCustomer() {
	return useContext(CustomerContext)
}

export const CustomerProvider = ({ children }) => {

	const { currentUser, brandId } = useAuth()

    //customer data -> {id: string, sent_email: string, response_email: string, valid: bool}
    const [customerData, setCustomerData] = useState([]) //change to default []
    //loading for customer data
	const [loading, setLoading] = useState(false);
    //loading changes when editing
	const [applyLoading, setApplyLoading] = useState(false);
	// dataObject for Shopify order used in refund page
	const [shopifyOrder, setShopifyOrder] = useState({})
	//notification data -> {id: string, sent_email: string, response_email: string, valid: bool}
	const [notificationData, setNotificationData] = useState([]) //change to default []
	// dataObject for the requested email - email links
	const [record, setEmailObj] = useState({})
	// For the agentId so when they sign an agent to something it sticks
	const [agentId, setAgentId] = useState(0);
	// Notepad so the team can take and save notes on emails
	const [notepad, setNotepad] = useState("");
	// For tracking when already sent or not
	const [alreadySent, setAlreadySent] = useState(false);
	// Length of the number of documents in the customerData
	const [ num, setNum ] = useState(500)
	// API Keys for Settings
	// const [ keys, setKeys ] = useState({})
	// Data for main analytics chart
	const [graphData, setGraphData] = useState({
        labels: [' January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
            {
                label: 'Emails sent per month',
                backgroundColor: 'rgba(116,56,254,255)',
                // borderColor: 'rgba(255,99,132,1)',
                // borderWidth: 1,
                hoverBackgroundColor: 'rgba(116,56,254,0.5)',
                // hoverBorderColor: 'rgba(255, 5,99,132,1)',
                data: [800, 3000, 2200, 1250, 600, 2000, 2600, 2800, 3100, 4200, 6000, 6500]
            }
        ]
    });
	const [graphNum, setGraphNum] = useState({cur: "23,534", prev: "21,242"})


	const [graphData2, setGraphData2] = useState({
        labels: [' January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
            {
                label: 'Emails sent per month',
                backgroundColor: 'rgba(116,56,254,255)',
                borderColor: 'rgba(116,56,254,255)',
                borderWidth: 5.5,
                hoverBackgroundColor: 'rgba(116,56,254,0.5)',
                // hoverBorderColor: 'rgba(255, 5,99,132,1)',
                data: [800, 3000, 2200, 1250, 600, 2000, 2600, 2800, 3100, 4200, 6000, 6500]
            }
        ]
    });
	const [graphNum2, setGraphNum2] = useState({cur: "23,534", prev: "21,242"})

	// For getting and tracking the current team
	const [team, setTeam] = useState([
        {
          key: '1',
          name: 'John Brown',
          tags: ['CX Specialist'],
        },
        {
          key: '2',
          name: 'Jim Green',
          tags: ['CX Specialist'],
        },
        {
          key: '3',
          name: 'Joe Black',
          tags: ['CX Specialist'],
        },
      ]);


	  const [ selectorTeam, setSelectorTeam ] = useState({
		label: 'Agents', options: [
			{label: 'Philip R', value: 1},
			{label: 'Stanley B', value: 2},
			{label: 'Clear', value: 0},
	]})  // getTeam2


	//convert to readable data object
	const convertData = (data) => {
		let temp = []
		for (let index = 0; index < data.length; index++) {
			let id = Object.keys(data[index])[0]
			temp.push(data[index][id])
			temp[index]["id"] = id
			temp[index]["key"] = index
		}
		console.log(temp)
		return temp
	}

    const getCusomterData = async (value) => {
        setLoading(true)
		const body = {"user": currentUser.uid, "value": value}
		try {
			const data = await apiClient.getCustomerData(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			setCustomerData(data)
			console.log(data)
            setLoading(false)
			return data
		} catch (err) {
			console.log("Unable to retrieve customer data")
			console.log(err)
			setCustomerData([])
            setLoading(false)
		}
	}



	// Search bar function
	// Will eventually need to be improved, but searches through many different
	// aspects of the firebase email objects
	const search = async (search) => {
		setLoading(true)
		console.log("Calling search with searchbar", search)

		const body = {
			"uid": currentUser.uid,
			"search": search
		}

		try {
			const data = await apiClient.search(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("Search data: ", data)
			setCustomerData(data.data)
			setNum(data.count)
			setLoading(false)
		} catch (error) {
			console.log("Unable to retrieve data specific to the search bar query.")
			setCustomerData([])
		}
	}



	// Get customerData within a range to significantly reduce the amount of reads
	// we must do to get the data, not only speeding it up significantly, reducing firebase data spent,
	// but also enabling us to do other things as well because of it.
	const getCustomerDataRange = async (startIndex, endIndex, pageSize) => {
        setLoading(true)

		console.log(`calling customerData in Range with startIndex: ${startIndex}, endIndex: ${endIndex}, and pageSize as ${pageSize} `)

		const body = {
			"user": currentUser.uid,
			"startIndex": startIndex,
			"endIndex": endIndex,
			"pageSize": pageSize
		}

		try {
			const data = await apiClient.getCustomerDataRange(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			setCustomerData(data)
			console.log("this is the data from the specific range: ", data)
            setLoading(false)
		} catch (err) {
			console.log("Unable to retrieve specific customer data")
			console.log("reading from the specific range failed: ", err)
			setCustomerData([])
            setLoading(false)
		}
	}


	const getDataWithFilter = async (startIndex, endIndex, pageSize, inbox, agent) => {
		// setCustomerData([])
        setLoading(true)
		// user, startIndex, endIndex, pageSize, inbox, agent

		console.log(`calling filter customer data in Range with startIndex: ${startIndex}, endIndex: ${endIndex}, and pageSize as ${pageSize} with inbox as ${inbox} and the agent id as ${agent} `)

		const body = {
			"user": currentUser.uid,
			"startIndex": startIndex,
			"endIndex": endIndex,
			"pageSize": pageSize,
			"inbox": inbox,
			"agent": agent,
		}

		try {
			const data = await apiClient.getDataWithFilter(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			setCustomerData(data.data)
			setNum(data.length)
			console.log("this is the filtered data with range: ", data)
            setLoading(false)
		} catch (err) {
			console.log("Unable to retrieve filtered customer data")
			console.log("reading from the filtered data in range failed: ", err)
			setCustomerData([])
            setLoading(false)
		}
	}


	const getNotificationData = async (uid) => {
        setLoading(true)
		const body = {"user": uid}
		try {
			const data = await apiClient.getNotificationData(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			setNotificationData(convertData(data))
			console.log(convertData(data))
            setLoading(false)
		} catch (err) {
			console.log("Unable to retrieve customer data")
			console.log(err)
			setNotificationData([])
            setLoading(false)
		}
	}

	// const acceptInvite = async (brandId, timeStamp) => {
	// 	const body = {
	// 		"brandId" : brandId,
	// 		"timeStamp": timeStamp
	// 	}

	// 	try{
	// 		const data = await apiClient.acceptInvite(body);
	// 		console.log(data);

	// 	}catch(error){
	// 		console.error(error);
	// 	}
	// }


	const getAiData = async (id) => {
		const body = {
			"id": id,
		}

		try{
			const data = await apiClient.getAiData(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			});
			console.log("Data from getAiData: ", data);
			return data
		}catch(error){
			console.error(error);
		}
	}



	const getShopifyData = async (email) => {
		const body = {
			"brandId": brandId,
			"email": email
		}

		try{
			const data = await apiClient.getShopifyData(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			});
			console.log("Data from getShopifyData: ", data)
			return data;
		}catch(error){
			console.error(error);
		}
	}


	const getShopifyRefund = async (uid, orderId) => {
        setLoading(true)
		const body = {"uid": uid, "orderId": orderId}
		try {
			const data = await apiClient.getShopifyOrderRefund(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			setShopifyOrder(data)
			setLoading(false)
		} catch (err) {
			console.log("Unable to retrieve customer data")
			console.log(err)
			setLoading(false)
		}
	}


	const getEmailFiles = async (id) => {
		const body = {"id": id, "brandId": brandId}
		console.log(body);
		try {
			const data = await apiClient.getEmailFiles(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("getEmailFiles ", data)
			return data;
		} catch (err) {
			console.log("Unable to retrieve email object")
			console.log(err)
			return null;
			// setLoading(false)
		}
	}


	const getEmailObj = async (id) => {
        // setLoading(true)
		const body = {"id": id, "brandId": brandId}
		try {
			const data = await apiClient.getEmailObject(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("getEmailObj ", data)
			// setLoading(false)
			return data;
		} catch (err) {
			console.log("Unable to retrieve email object")
			console.log(err)
			return null;
			// setLoading(false)
		}
	}


	const pagination = async (pageSize, sortOrder) => {
		const body = { "brandId": brandId, "pageSize": pageSize, "sortOrder": sortOrder  }
		try {
			const data = await apiClient.pagination(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("pagination: ", data)
			return data;
		} catch (error) {
			console.log("Error: ", error)
			return [];
		}
	}

	const searchEmails = async(keyword, page) => {
		const body = { "brandId": brandId, "keyword": keyword, "page":page}
		try{
			const data = await apiClient.searchEmails(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			});
			console.log(data);
			return data;
		}catch(error){
			console.error(error);
			return[]
		}
	}


	const getTicketCount = async () => {
		const body = {"brandId": brandId }
		try{
			const data = await apiClient.getTicketCount(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			});
			console.log(data);
			return data;
		}catch(error){
			console.error(error);
		}
	}


	const paginationForward = async (pageNumber, pageSize, ref, sortOrder) => {
		const body = { "brandId": brandId, "pageNumber": pageNumber, "pageSize": pageSize, "ref": ref, "sortOrder": sortOrder }
		try {
			const data = await apiClient.paginationForward(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("paginationForward: ", data)
			return data;
		} catch (error) {
			console.log("Error: ", error)
			return [];
		}
	}


	const paginationBackward = async (pageNumber, pageSize, ref, sortOrder) => {
		const body = { "brandId": brandId, "pageNumber": pageNumber, "pageSize": pageSize, "ref": ref, "sortOrder": sortOrder }
		try {
			const data = await apiClient.paginationBackward(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("paginationBackward: ", data)
			return data;
		} catch (error) {
			console.log("Error: ", error)
			return [];
		}
	}


	const deleteEmail = async (id) => {
		// setApplyLoading(true)

		const body = {
			"uid": currentUser.uid,
			"id": id
		}

		try{
			const data = await apiClient.deleteEmail(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log(data)
			// setApplyLoading(false)
			return true
		} catch(err){
			console.log(`Unable send data ${id}`)
			console.log(err)
			// setApplyLoading(false)
			return false
		}
	}


	const updateAgent = async (id, newAgentId) => {
		// setApplyLoading(true)
		setAgentId(newAgentId)

		const body = {
			"uid": currentUser.uid,
			"id": id,
			"newAgentId": newAgentId
		}

		try{
			const data = await apiClient.updateAgent(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log(data)
			return true
		} catch(err){
			console.log(`Unable send data ${id}`)
			console.log(err)
			return false
		}
	}


	const updateNotepad = async (id, noteData) => {
		// setApplyLoading(true)
		setNotepad(noteData)

		const body = {
			"uid": currentUser.uid,
			"id": id,
			"noteData": noteData
		}

		try{
			console.log("about to call the backend for updateNotepad", id, noteData)
			const data = await apiClient.updateNotepad(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log(data)
			return true
		} catch(err){
			console.log(`Unable send data ${id}`)
			console.log(err)
			return false
		}
	}


	const setAutomations = async (identifier, value) => {
		const body = {"uid": currentUser.uid, "identifier": identifier, "value": value}
		try {
			const data = await apiClient.setAutomations(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("Set automations data: ", data)
		} catch (error) {
			console.log("Error in set automations: ", error)
		}
	}


	// When the user inputs their FAQ data it will be saved to the database using
	// this function
	const setFAQ = async (identifier, value) => {
		console.log("identifier ", identifier);
		console.log("value ", value);
		const body = {"uid": brandId, "identifier": identifier, "value": value}
		try {
			const data = await apiClient.setFAQ(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("Set FAQ data, ", data)
		} catch (error) {
			console.log("Error setting FAQ description, ", error)
		}
	}


	const getAutomations = async () => {
		const body = {"uid": currentUser.uid}

		try {
			const data = await apiClient.getAutomations(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			// console.log("getAutomations data: ", data)
			return data
		} catch (error) {
			console.log("Error getting automations: ", error)
			return {};
		}
	}


	const getFAQ = async() => {
		const body = {"uid": currentUser.uid}

		try {
			const data = await apiClient.getFAQ(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("FAQ data: ", data)
			return data;
		} catch (error) {
			console.log("Eror getting FAQ, ", error)
		}
	}


	const getGraphData = async (startDate, endDate) => {

		const body = {
			"uid": currentUser.uid,
			"startDate": startDate,
			"endDate": endDate
		}

		try {
			const data = await apiClient.getGraphData(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("Graph data: ", data)
			setGraphData(data)

			const asString =  (data.totalCount).toString();
			const asString2 =  (data.actualCount).toString();
			setGraphNum({cur: asString, prev: asString2})
		} catch (error) {
			console.error(error);
		}
	}



	const getGraphData2 = async (startDate, endDate) => {

		const body = {
			"uid": currentUser.uid,
			"startDate": startDate,
			"endDate": endDate
		}

		try {
			const data = await apiClient.getGraphData2(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("Graph data: ", data)
			setGraphData2(data)

			const asString =  (data.average).toString();
			setGraphNum2({cur: data.average, prev: "null"})
		} catch (error) {
			console.error(error);
		}
	}



	const getTeam = async () => {

		const body = {
			"uid": currentUser.uid,
		}

		try {
			const data = await apiClient.getTeam(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("Team data: ", data)
			setTeam(data)
		} catch (error) {
			console.error(error);
		}
	}


	const getTeam2 = async () => {

		console.log("in getTeam22")

		const body = {
			"uid": currentUser.uid,
		}

		try {
			const data = await apiClient.getTeam2(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("Team data: ", data)
			setSelectorTeam(data)
		} catch (error) {
			console.error(error);
		}
	}


	const sendInvite = async (brandId, emailAddress) => {
		const body = {
			"brandId": brandId,
			"emailAddress": emailAddress
		}

		try{
			const data = await apiClient.sendInvite(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			});
			return data
		}catch(error){
			console.error(error)
		}
	}

	const acceptInvite = async (uid, brandId, stamp) => {
		const body = {
			"uid" : uid,
			"brandId" : brandId,
			"stamp": stamp,
		}

		try{
			const data = await apiClient.acceptInvite(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			});
			console.log(data);
			return data;

		}catch(error){
			console.error(error);
		}
	}


	const checkUser = async (uid) => {
		const body = {
			"uid": uid
		}

		try{
			const data = await apiClient.checkUser(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			});
			console.log(data);
			return data;

		}catch(error){
			console.error(error);
		}
	}


	const addTeam = async (name) => {

		const body = {
			"uid": currentUser.uid,
			"name": name
		}

		try {
			const data = await apiClient.addTeam(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("Add team: ", data)
			return true
		} catch (error) {
			console.error(error);
			return false
		}
	}






	const deleteTeam = async (id) => {

		const body = {
			"uid": currentUser.uid,
			"id": id
		}

		try {
			const data = await apiClient.deleteTeam(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("Delete team: ", data)
			return true
		} catch (error) {
			console.error(error);
			return false
		}
	}



	function formatTime(minutes) {
		const hours = Math.floor(minutes / 60);
		const mins = Math.floor(minutes % 60);
		const secs = Math.round((minutes - Math.floor(minutes)) * 60);
		
		if (hours > 0) {
		  return `${hours}hr ${mins}min`;
		} else if (mins > 0) {
		  return `${mins}min ${secs.toString().padStart(2, '0')}sec`;
		} else {
		  return `0:${secs.toString().padStart(2, '0')}sec`;
		}
	  }

	// const getGraphNum = async (startDate, endDate) => {

	// 	const body = {
	// 		"uid": currentUser.uid,
	// 		"startDate": startDate,
	// 		"endDate": endDate
	// 	}

	// 	try {
	// 		const data = await apiClient.getGraphNum(body)
	// 		console.log("Graph data: ", data)
	// 		setGraphData(data)
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// }

	
	// const acceptInvite = async (brandId, timeStamp) => {
	// 	const body = {
	// 		"brandId" : brandId,
	// 		"timeStamp": timeStamp
	// 	}

	// 	try{
	// 		const data = await apiClient.acceptInvite(body);
	// 		console.log(data);

	// 	}catch(error){
	// 		console.error(error);
	// 	}
	// }


		// Currently  Unused
		const uploadFile = async (file) => {
			const formData = new FormData();
			formData.append('file', file);
		
			try {
				const response = await apiClient.uploadFile(formData);
				console.log(response);
				return response;
			} catch (error) {
				console.error(error);
			}
		};

		
		const removeFile = async (filename) => {
			const body = {
				"filename" : filename
			}
			
			try{
				const response = await apiClient.removeFile(body, {
					id: await currentUser.getIdToken(), // or any other method to obtain the ID token
					apiKey: process.env.REACT_APP_API_KEY,
				});
				return response;
			}catch(error){
				console.error(error);
			}
		}

    const sendEmail = async (id, subject, brand, to, html, threadId) => {
		console.log("in sendEmail CustomerProvider")

		// setApplyLoading(true)
		const body = {
			"id": id,
			"subject": subject,
			"brand": brand,
			"to": to,
			"html": html,
			"threadId": threadId,
		}

		console.log(body)

		// First try to send the email and respond if it sent or not
		try {
			const data = await apiClient.sendEmail(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			});
			console.log("Data from sendEmail: ", data);
			return data;
		} catch (error) {
			return error
			console.log("Error sending mail: ", error);
		}
	}

	// Get the keys for the settings page
	const getKeys = async () => {
		const body = {
			"uid": brandId,
		}
		try {
			const data = await apiClient.getKeys(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			return data;
		} catch (error) {
			console.error(error);
			return {};
		}
	}


	const getGorgiasKeys = async () => {
		const body = {
			"uid": brandId,
		}
		try {
			const data = await apiClient.getGorgiasKeys(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			return data;
		} catch (error) {
			console.error(error);
			return {};
		}
	}

	// Send API keys to the db
	const sendApiKeys = async (data) => {
		const body = {
			"uid": brandId,
			"data": data,
		}
		try {
			const data = await apiClient.sendApiKeys(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("Keys successfully sent! ")
			return data;
		} catch (error) {
			console.error("Sending API Keys Error: ", error);
		}
	}


	const gorgiasKeys = async (data) => {
		const body = {
			"uid": brandId,
			"data": data,
		}
		try {
			const data = await apiClient.gorgiasKeys(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("Keys successfully sent! ")
			return data;
		} catch (error) {
			console.error("Sending API Keys Error: ", error);
		}
	}

	const trackingKey = async (key) => {
		const body = {
			"uid": brandId,
			"key": key,
		}
		try {
			const data = await apiClient.trackingKey(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("Keys successfully sent! ")
			return data;
		} catch (error) {
			console.error("Sending API Keys Error: ", error);
		}
	}

	
	const getTrackingKey = async () => {
		const body = {
			"uid": brandId,
		}
		try {
			const data = await apiClient.getTrackingKey(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("Keys retrieved successfully! ")
			if(data.success){
				return data.decryptedValue;
			} else {
				return null;
			}
		} catch (error) {
			console.error("Get 17track API Keys Error: ", error);
		}
	}


	const getAnalytics = async (analytics, brands) => {
		if(!brands){
			return null;
		}

		if(!analytics){
			return null;
		}

		const body = {
			"brandId": brands,
			"analytics": analytics,
		}
		try {
			const data = await apiClient.getAnalytics(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			// console.log("Analytics retrieved!: ", data)
			return data;
		} catch (error) {
			console.error("Analytics error: ", error);
		}
	}

	// A lot more to do with integrations from here on, probably better to separate later
	const integrateGoogle = async () => {
		const body = {
			"uid": currentUser.uid,
		}
		
		let url = `https://app.mergelabs.co/auth/google`
		let uid = currentUser.uid
		
		window.open(`${url}?uid=${uid}`);
	}

	
	const isGoogleAuthenticated = async () => {
		console.log("In isGoogleAuthenticated with the UID as: ", currentUser.uid)
		const body = {
			"uid": currentUser.uid,
		}

		try {
			const data = await apiClient.isGoogleAuthenticated(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("Fetching Google auth status..", data)
			return data;
		} catch(err) {
			console.log(`Unable to get Gmail status: ${err}`)
			return false;
		}
	}

	
	const isShopifyAuthenticated = async () => {
		console.log("In isShopifyAuthenticated with the UID as: ", currentUser.uid)
		const body = {
			"uid": currentUser.uid,
		}

		try {
			const data = await apiClient.isShopifyAuthenticated(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("Fetching Shopify auth status..", data)
			return data;
		} catch(err) {
			console.log(`Unable to get Shopify status: ${err}`)
			return false;
		}
	}


	const createAccount = async (email, pass) => {
		console.log("Creating new account..")
		console.log("Email: ", email)
		console.log("Password: ", pass)

		const body = {
			"uid": currentUser.uid,
			"email": email,
			"pass": pass,
		}

		try {
			const data = await apiClient.createAccount(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("Successfully created account: ", data)
			return data;
		} catch(err) {
			console.log(`Couldn't create account: ${err}`)
			return err;
		}
	}


	const setUser = async (uid, data) => {

		console.log("CustomerProvider.js > setUser")

		const body = {
			"uid": uid,
			"data": data
		}

		try {
			const data = await apiClient.setUser(body, {
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("CustomerProvider.js > setUser: Successful! ")
		} catch (error) {
			console.error("CustomerProvider.js > setUser: Error: ", error);
		}
	}



	const getUser = async () => {

		console.log("CustomerProvider.js > getUser")

		const body = {
			"uid": currentUser.uid,
		}

		try {
			const data = await apiClient.getUser(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("CustomerProvider.js > getUser: Successful! ")
			return data;
		} catch (error) {
			console.error("CustomerProvider.js > getUser: Error: ", error);
			return {};
		}
	}

	
	const setWorkspace = async (data) => {

		console.log("CustomerProvider.js > setWorkspace")

		if(!brandId){
			console.log("No brandId in setWorkspace..")
			return;
		}

		const body = {
			"brandId": brandId,
			"data": data
		}

		try {
			const data = await apiClient.setWorkspace(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("CustomerProvider.js > setWorkspace: Successful! ")
		} catch (error) {
			console.error("CustomerProvider.js > setWorkspace: Error: ", error);
		}
	}


	const sendSlackMessage = async (message) => {

		console.log("CustomerProvider.js > sendSlackMessage")

		const body = {
			"message": message,
		}

		try {
			const data = await apiClient.sendSlackMessage(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("CustomerProvider.js > sendSlackMessage: Successful! ")
		} catch (error) {
			console.error("CustomerProvider.js > sendSlackMessage: Error: ", error);
		}
	}


	const setShopifyKeys = async (brand_id, shopify) => {

		console.log("CustomerProvider.js > setShopifyKeys")
		console.log("brandId is : ", brand_id)

		if(!brand_id){
			console.log("No brandId in setShopifyKeys..")
			return;
		}

		const body = {
			"brandId": brand_id,
			"shopify": shopify
		}

		try {
			const data = await apiClient.setShopifyKeys(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			return data.success;
			console.log("CustomerProvider.js > setShopifyKeys: Successful! ")
		} catch (error) {
			console.error("CustomerProvider.js > setShopifyKeys: Error: ", error);
		}
	}


	const isNylas = async (brand_id) => {

		console.log("CustomerProvider.js > isNylas")
		console.log("brandId is : ", brand_id)

		if(!brand_id){
			console.log("No brandId in isNylas..")
			return false;
		}

		const body = {
			"brandId": brand_id
		}

		try {
			const data = await apiClient.isNylas(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("data: ", data)
			return data
			console.log("CustomerProvider.js > isNylas: Successful! ")
		} catch (error) {
			console.error("CustomerProvider.js > isNylas: Error: ", error);
		}
	}


	const isShopify = async (brand_id) => {		

		console.log("CustomerProvider.js > isShopify")
		console.log("brandId is : ", brand_id)

		if(!brand_id){
			console.log("No brandId in isShopify..")
			return;
		}

		const body = {
			"brandId": brand_id
		}

		try {
			const data = await apiClient.isShopify(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("data: ", data)
			return data
			console.log("CustomerProvider.js > isShopify: Successful! ")
		} catch (error) {
			console.error("CustomerProvider.js > isShopify: Error: ", error);
		}
	}

	// Create brand
	const createBrand = async(name) => {
		const body = {
			"uid": currentUser.uid,
			"name": name
		}
		try{
			const data = await apiClient.createBrand(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			});
			console.log(data);
			return data;
		}catch(error){
			console.error(error);
		}
	}


	const addBrand = async(uid) => {
		const body = {
			"uid": uid,
			"brandId": brandId
		}
		try{
			const data = await apiClient.addBrand(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			});
			console.log(data);
			if(data?.success === true){
				return true
			} else {
				return null;
			}
		}catch(error){
			console.error(error);
		}
	}


	// Get brand values
	const brandValues = async (arr) => {
		const body = {
			"brandId": brandId,
			"arr": arr,
		}
		try {
			const data = await apiClient.brandValues(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			// console.log("Brand values received.")
			return data;
		} catch (error) {
			console.error("Brand value Error: ", error);
		}
	}


	const setBrandValues = async (arr) => {
		// console.log("setBrandValues: ", arr)
		const body = {
			"brandId": brandId,
			"arr": arr,
		}
		try {
			const data = await apiClient.setBrandValues(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			});
			// console.log("Brand values set.")
			return data;
		} catch (error) {
			console.error("Brand value's set error: ", error);
		}
	}

	
	const createApi = async (name, email, apiKey) => {
		const body = {
			"name": name,
			"email": email,
			"apiKey": apiKey,
		}
		try {
			const data = await apiClient.createApi(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			});
			// console.log("Brand values set.")
			return data;
		} catch (error) {
			console.error("createApi error: ", error);
		}
	}


	const stripeUsage = async () => {

		const body = {
			"brandId": brandId,
		}

		try {
			const data = await apiClient.stripeUsage(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("stripeUsage")
			return data;
		} catch (error) {
			console.error("stripeUsage Error: ", error);
		}
	}


	const getStripeLink = async (brandId, lm_data) => {

		const body = {
			"brandId": brandId,
			"lm_data": lm_data
		}

		try {
			const data = await apiClient.getStripeLink(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("stripeCreate")
			return data;
		} catch (error) {
			console.error("getStripeLink Error: ", error);
		}
	}



	const stripeSubscribe = async (email) => {

		const body = {
			"email": email,
			"brandId": brandId
		}

		try {
			const data = await apiClient.stripeSubscribe(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("stripeSubscribe")
			return data;
		} catch (error) {
			console.error("stripeSubscribe Error: ", error);
		}
	}


	const stripeAccount = async () => {

		const body = {
			"brandId": brandId
		}

		try {
			const data = await apiClient.stripeAccount(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("stripeAccount: ", data)
			return data;
		} catch (error) {
			console.error("stripeAccount Error: ", error);
		}
	}

	
	const setNylas = async (code) => {
		const body = {
			"brandId": brandId,
			"code": code
		}
		try {
			const data = await apiClient.setNylas(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("setNylas: ", data)
			return data;
		} catch (error) {
			console.error("setNylas Error: ", error);
		}
	}

	const nylasEmail = async () => {

		const body = {
			"id": brandId,
		}

		try {
			const data = await apiClient.nylasEmail(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("nylasEmail")
			return data;
		} catch (error) {
			console.error("nylasEmail Error: ", error);
		}
	}


	const setUsersBrands = async () => {
		console.log("in setUserBrands to add the brand to the users brands")
		const body = {
			"uid": currentUser.uid,
			"brandId": brandId,
		}
		try {
			const data = await apiClient.setUsersBrands(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("setUsersBrands")
			return data;
		} catch (error) {
			console.error("setUsersBrands Error: ", error);
		}
	}


	const addIntegration = async (obj) => {
		console.log("addIntegration")
		const body = {
			"brandId": brandId,
			"data": obj,
		}
		try {
			const data = await apiClient.addIntegration(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			// console.log("setUsersBrands")
			return data;
		} catch (error) {
			console.error("addIntegration Error: ", error);
		}
	}

	const getIntegrations = async () => {
		// console.log("getIntegrations")
		const body = {
			"brandId": brandId,
		}
		if(brandId){
			try {
				const data = await apiClient.getIntegrations(body, {
					id: await currentUser.getIdToken(), // or any other method to obtain the ID token
					apiKey: process.env.REACT_APP_API_KEY,
				})
				if(data.success === true){
					return data.data;
				} else {
					console.log("Error in getIntegrations: ", data.error || 'Error getting error.')
					return [];
				}
			} catch (error) {
				console.error("addIntegration Error: ", error);
			}
		}
	}


	const saveIntegration = async (data, id) => {
		console.log("saveIntegration");
		const body = {
			"brandId": brandId,
			"data": data,
			"id": id,
		}
		try {
			const data = await apiClient.saveIntegration(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("saveIntegration")
			return data;
		} catch (error) {
			console.error("saveIntegration Error: ", error);
		}
	}

	const deleteIntegration = async (id) => {
		console.log("deleteIntegration")
		const body = {
			"brandId": brandId,
			"id": id,
		}
		try {
			const data = await apiClient.deleteIntegration(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			console.log("deleteIntegration")
			return data;
		} catch (error) {
			console.error("deleteIntegration Error: ", error);
		}
	}


	const getApi = async () => {
		const body = {
			"uid": currentUser.uid,
		}
		try {
			const data = await apiClient.getApi(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			return data;
		} catch (error) {
			console.error("getApi Error: ", error);
		}
	}


	const resetApi = async () => {
		const body = {
			"uid": currentUser.uid,
		}
		try {
			const data = await apiClient.resetApi(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			return data;
		} catch (error) {
			console.error("resetApi Error: ", error);
		}
	}


	const addClient = async (client) => {
		const body = {
			"uid": currentUser.uid,
			"client": client
		}
		try {
			const data = await apiClient.addClient(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			return data;
		} catch (error) {
			console.error("addClient Error: ", error);
		}
	}


	const getClients = async (client) => {
		const body = {
			"uid": currentUser.uid,
		}
		try {
			const data = await apiClient.getClients(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			return data;
		} catch (error) {
			console.error("getClients Error: ", error);
		}
	}


	const deleteClient = async (clientId) => {
		const body = {
			"uid": currentUser.uid,
			"clientId": clientId,
		}
		try {
			const data = await apiClient.deleteClient(body, {
				id: await currentUser.getIdToken(), // or any other method to obtain the ID token
				apiKey: process.env.REACT_APP_API_KEY,
			})
			return data;
		} catch (error) {
			console.error("deleteClient Error: ", error);
		}
	}


    useEffect(() => {
		if(currentUser) {
			console.log("Current user: ", currentUser.uid)
			setCustomerData([])
			
			// getCusomterData(currentUser.uid)
			// getNotificationData(currentUser.uid)
			// setCustomerData(convertData(TEST)) // FOR TESTING ONLY
		}
	}, [currentUser])


    return (
		<CustomerContext.Provider
			value={{
				customerData, setCustomerData,
				applyLoading, setApplyLoading,
				loading, setLoading,
				notificationData, setNotificationData,
				getNotificationData,
				getCusomterData,
				getCustomerDataRange,
				getDataWithFilter,
				removeFile,uploadFile, sendEmail,
				deleteEmail,
				getGraphData,
				getGraphData2,
				updateAgent,
				updateNotepad,
				shopifyOrder, setShopifyOrder, getAiData,
				getShopifyData, getShopifyRefund,
				record, setEmailObj,
				agentId, setAgentId,
				notepad, setNotepad,
				graphData, setGraphData,
				graphData2, setGraphData2,
				graphNum2, setGraphNum2,
				graphNum, setGraphNum,
				selectorTeam, setSelectorTeam,
				num, setNum,
				sendInvite, acceptInvite, checkUser,
				addTeam, deleteTeam,
				team, setTeam,
				getTeam, getTeam2,
				getEmailFiles, getEmailObj, setAutomations,
				setFAQ, getAutomations,
				getFAQ, search,
				integrateGoogle, isGoogleAuthenticated,
				getKeys, sendApiKeys, isShopifyAuthenticated,
				createAccount, setUser,
				setShopifyKeys, setWorkspace,
				isNylas, isShopify,
				paginationBackward, paginationForward, searchEmails, getTicketCount,
				pagination, getUser, sendSlackMessage,
				brandValues, stripeUsage, nylasEmail,
				getStripeLink, stripeSubscribe, stripeAccount,
				createBrand, setBrandValues, setUsersBrands,
				setNylas, addIntegration, getIntegrations, saveIntegration, deleteIntegration,
				gorgiasKeys, getGorgiasKeys, getApi, resetApi, trackingKey, getTrackingKey,
				getAnalytics, addBrand, createApi,
				addClient, getClients, deleteClient
			}}
		>
			{children}
		</CustomerContext.Provider>
    )
}