import React from 'react'
import { AuthProvider } from './AuthProvider'
import { CustomerProvider } from './CustomerProvider'
import { ThemeProvider } from './ThemeProvider'
import { NylasProvider } from '@nylas/nylas-react';
import { NextUIProvider } from "@nextui-org/react";


const Provider = ({ children }) => {
    return (
        <AuthProvider>
                <NylasProvider serverBaseUrl={'https://app.mergelabs.co'}>
                    <CustomerProvider>
                        <ThemeProvider>
                            {children}
                        </ThemeProvider>
                    </CustomerProvider>
                </NylasProvider>
        </AuthProvider>
    )
}

export default Provider