import styles from './charts.css';
import React, { useState, useEffect } from 'react'
import { useCustomer } from '../../context/CustomerProvider';
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { Bar, Line } from "react-chartjs-2"
import { DatePicker, Space, Radio, Select, Button, Input } from 'antd';
import moment from "moment";
import axios from "axios";
import Menu from "../../component/Menu"
import { Chart as ChartJS, BarElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, registerables } from "chart.js";
import { useAuth } from '../../context/AuthProvider';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

ChartJS.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, ...registerables);

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;


const Charts = () => {

  const { currentUser, brandId, brands, triggerBrand } = useAuth();

  const { getAnalytics, getClients } = useCustomer();

  const [currentBrand, setCurrentBrand] = useState({ id: null, name: null });
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedRange, setSelectedRange] = useState("today");
  const [searchQuery, setSearchQuery] = useState('');


  const [custom, setCustom] = useState(false);
  // const [customStart, setCustomStart] = useState(formatDate(Date.now()));
  // const [customEnd, setCustomEnd] = useState(formatDate(Date.now()));
  const [customDateRange, setCustomDateRange] = useState({
    start: formatDate(Date.now()),
    end: formatDate(Date.now())
  });

  const [analytics, setAnalytics] = useState({});
  const [graphData, setGraphData] = useState({
    labels: ['Date 1', 'Date 2', 'Date 3', 'Date 4'], // Dates for the x-axis
    datasets: [
      {
        label: 'Dataset 1',
        data: [10, 20, 30, 40], // Data for dataset 1
        backgroundColor: 'rgba(255, 99, 132, 0.2)', // Background color for bars in dataset 1
        borderColor: 'rgba(255, 99, 132, 1)', // Border color for bars in dataset 1
        borderWidth: 1, // Border width for bars in dataset 1
      },
      {
        label: 'Dataset 2',
        data: [15, 25, 35, 45], // Data for dataset 2
        backgroundColor: 'rgba(54, 162, 235, 0.2)', // Background color for bars in dataset 2
        borderColor: 'rgba(54, 162, 235, 1)', // Border color for bars in dataset 2
        borderWidth: 1, // Border width for bars in dataset 2
      },
      {
        label: 'Dataset 3',
        data: [20, 30, 40, 50], // Data for dataset 3
        backgroundColor: 'rgba(255, 206, 86, 0.2)', // Background color for bars in dataset 3
        borderColor: 'rgba(255, 206, 86, 1)', // Border color for bars in dataset 3
        borderWidth: 1, // Border width for bars in dataset 3
      },
      {
        label: 'Dataset 4',
        data: [25, 35, 45, 55], // Data for dataset 4
        backgroundColor: 'rgba(75, 192, 192, 0.2)', // Background color for bars in dataset 4
        borderColor: 'rgba(75, 192, 192, 1)', // Border color for bars in dataset 4
        borderWidth: 1, // Border width for bars in dataset 4
      },
    ],
  });

  const [messages, setMessages] = useState(999);
  const [messagesNoFlag, setMessagesNoFlag] = useState(999);
  const [flagged, setFlagged] = useState(999);
  const [automated, setAutomated] = useState(999);
  const [errors, setErrors] = useState(999);

  // Ratios
  const [messagesNoFlagRatio, setMessagesNoFlagRatio] = useState(0.1);
  const [flagRatio, setFlagRatio] = useState(0.1);
  const [automateRatio, setAutomateRatio] = useState(0.1);
  const [errorRatio, setErrorRatio] = useState(0.1);

  const [calculatedStart, setCalculatedStart] = useState(formatDate(Date.now()));
  const [calculatedEnd, setCalculatedEnd] = useState(formatDate(Date.now()));

  const analyticTypes = [
    "all_messages",
    "unnecessary",
    "messages_billable",
    "flagged",
    "not_flagged",
    "automated",
    "ai_error",
    "ai_error_2"
  ];


  useEffect(() => {
    // This function will be called whenever selectedOption changes
    console.log("The brands have been changed and set at this point.")
    console.log(selectedOption) // ['all']

    const func = async () => {
      // Create a new array without the "all" value and add the IDs of all brands
      const updatedSelectedOption = selectedOption.includes('all')
        ? brands.map(brand => brand.id)
        : selectedOption;

      console.log("Fetching analytics data..")
      // console.log("updatedSelectionsOption: ", updatedSelectedOption)
      // console.log("Analytic types:: ", analyticTypes)

      const response = await getAnalytics(analyticTypes, updatedSelectedOption);
      console.log("Response: ", response);

      if (!response) {
        return;
      } else {
        setAnalytics(response);
        const chartData = formatChartData(response, null, calculatedStart, calculatedEnd);
      }
    };

    func();
  }, [selectedOption]); // Dependency array includes selectedOption


  useEffect(() => {
    // This function will be called whenever customDateRange changes
    console.log("The dates have been changed and fully updated at this point.")
    console.log(customDateRange) // {start: '20240727', end: '20240727'}
    setCalculatedStart(customDateRange.start)
    setCalculatedEnd(customDateRange.end)
  }, [customDateRange]); // Dependency array includes customDateRange


  useEffect(() => {
    // This function will be called whenever selectedOption changes
    console.log("The calculated dates have loaded in.")
    console.log({ calculatedStart, calculatedEnd }) // {calculatedStart: '20240701', calculatedEnd: '20240801'}

    // Check if selectedOption is empty
    if (!selectedOption || selectedOption.length === 0) {
      return;
    }

    // Check if analytics is an empty object or not set
    if (!analytics || Object.keys(analytics).length === 0) {
      const fetchData = async () => {
        // Create a new array without the "all" value and add the IDs of all brands
        const updatedSelectedOption = selectedOption.includes('all')
          ? brands.map(brand => brand.id)
          : selectedOption;

        console.log("Fetching analytics data..")

        const response = await getAnalytics(analyticTypes, updatedSelectedOption);
        console.log("Response::: ", response);

        if (!response) {
          return;
        } else {
          setAnalytics(response);
          const chartData = formatChartData(response, null, calculatedStart, calculatedEnd);
          // Do something with chartData if needed
        }
      };

      fetchData();
    } else {
      // If analytics data is already available, just format the chart data
      const chartData = formatChartData(analytics, null, calculatedStart, calculatedEnd);
      // Do something with chartData if needed
    }

  }, [calculatedStart, calculatedEnd]); // Dependency array includes selectedOption


  function getSumByKey(data = analytics, requestedKey, startDate = null, endDate = null) {

    let sum = 0;

    if (startDate && endDate) {
      const dates = getAllDatesBetween(startDate, endDate);
      sum = dates.reduce((acc, date) => {
        return acc + (data[requestedKey]?.[date] || 0);
      }, 0);
    } else if (startDate) {
      sum = data[requestedKey]?.[startDate] || 0;
    }
    return sum;
  }



  function formatChartData(data = analytics, date = null, startDate = null, endDate = null) {
    // console.log("format chart data: ", data);
    // Get all dates between startDate and endDate
    const labels = getAllDatesBetween(startDate, endDate);
    const datasets = [];
    let totalMessagesNoFlag = 0;
    let totalMessages = 0;
    let totalFlagged = 0;
    let totalAutomated = 0;
    let totalAiErrors = 0;
    let totalAllMessages = 0;

    // Initialize datasets for messages and flagged messages
    const messagesData = labels.map(() => 0);
    const flaggedData = labels.map(() => 0);

    // Iterate over each brand's data
    Object.values(data).forEach(brandData => {
      if (brandData) {
        // Accumulate messages and flagged messages data for each date
        labels.forEach((date, index) => {
          messagesData[index] += brandData['not_flagged']?.[date] || 0;
          flaggedData[index] += brandData['flagged']?.[date] || 0;
        });

        // Sum up the values for each brand
        totalMessagesNoFlag += getSumByKey(brandData, "not_flagged", startDate, endDate);
        totalMessages += getSumByKey(brandData, "messages_billable", startDate, endDate);
        totalFlagged += getSumByKey(brandData, "flagged", startDate, endDate);
        totalAutomated += getSumByKey(brandData, "automated", startDate, endDate);
        totalAiErrors += getSumByKey(brandData, "ai_error", startDate, endDate);
        totalAllMessages += getSumByKey(brandData, "all_messages", startDate, endDate);
      }
    });

    // Add messages and flagged datasets to the chart
    datasets.push({
      label: 'Messages',
      data: messagesData,
      backgroundColor: 'rgba(116,56,254,255)',
      borderColor: 'rgba(116,56,254,255)',
      borderWidth: 1
    });

    datasets.push({
      label: 'Flagged',
      data: flaggedData,
      backgroundColor: 'rgba(209,195,242,255)',
      borderColor: 'rgba(209,195,242,255)',
      borderWidth: 1
    });

    setGraphData({ labels, datasets });
    setMessagesNoFlag(totalMessagesNoFlag);
    setMessages(totalMessages);
    setFlagged(totalFlagged);
    const flagRatio = (totalMessages !== 0) ? Math.round((totalFlagged / totalMessages) * 1000) / 10 : 0.1;
    setFlagRatio(flagRatio);
    setMessagesNoFlagRatio(100 - flagRatio);
    setAutomated(totalAutomated);
    const automateRatio = (totalMessages !== 0) ? Math.round((totalAutomated / totalMessages) * 1000) / 10 : 0.1;
    setAutomateRatio(automateRatio);
    setErrors(totalAiErrors);
    const errorRatio = (totalAllMessages !== 0) ? Math.round((totalAiErrors / totalAllMessages) * 1000) / 10 : 0.1;
    setErrorRatio(errorRatio);

    return { labels, datasets };
  }



  function getAllDatesBetween(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate.slice(0, 4), startDate.slice(4, 6) - 1, startDate.slice(6, 8));
    const end = new Date(endDate.slice(0, 4), endDate.slice(4, 6) - 1, endDate.slice(6, 8));

    while (currentDate <= end) {
      dates.push(currentDate.toISOString().slice(0, 10).replace(/-/g, ''));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }


  // Helper function to increment date in yyyymmdd format
  function incrementDate(date) {
    const year = parseInt(date.substring(0, 4));
    const month = parseInt(date.substring(4, 6));
    const day = parseInt(date.substring(6, 8));
    const nextDate = new Date(year, month - 1, day + 1);
    return `${nextDate.getFullYear()}${(nextDate.getMonth() + 1).toString().padStart(2, '0')}${nextDate.getDate().toString().padStart(2, '0')}`;
  }


  function getRandomColor(index) {
    const colors = [
      [255, 99, 132], // Red
      [54, 162, 235], // Blue
      [255, 206, 86], // Yellow
      [75, 192, 192]  // Green
    ];
    return colors[index % colors.length].join(',');
  }

  const graphOptions = [
    {
      value: 'today',
      label: 'Today',
    },
    {
      value: 'yesterday',
      label: 'Yesterday',
    },
    {
      value: 'thisWeek',
      label: 'This Week',
    },
    {
      value: 'thisMonth',
      label: 'This Month',
    },
    {
      value: 'thisYear',
      label: 'This Year',
    },
    {
      value: 'custom',
      label: 'Custom',
    },
  ];

  // const [custom, setCustom] = useState(false)

  const settingOptions = {
    plugins: {
      title: {
        display: false
      },
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        stacked: true,
        display: false,
        grid: {
          display: false
        }
      },
      y: {
        stacked: true,
        display: false,
        grid: {
          display: false
        }
      }
    },
    maintainAspectRatio: false,
  };



  function handleDateRangeChange(dates) {
    console.log("handle date range change")
    // setSelectedDates(dates);
    // Call your function with the selected dates here
    console.log('Selected dates: ', dates[0].toDate(), dates[1].toDate());
    const startDate = formatDate(dates[0].toDate());
    const endDate = formatDate(dates[1].toDate());

    setCustomDateRange({
      start: startDate,
      end: endDate
    });

    // const chartData = formatChartData(analytics, null, startDate, endDate);
  }



  function formatDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    return `${year}${month}${day}`;
  }


  const onChange = (selectedValue) => {
    console.log(`Selected ${selectedValue}`);
    setCustom(false)

    if (selectedValue === "custom") {
      setCustom(true)
      setSelectedRange(selectedValue);
    } else {
      setSelectedRange(selectedValue);
      calculate(selectedValue);
    }
  };


  const calculate = (range) => {
    let startDate, endDate;

    // Set up dates based on selected value
    switch (range) {
      case 'today':
        startDate = endDate = getCurrentDate();
        break;
      case 'yesterday':
        startDate = endDate = getYesterdayDate();
        break;
      case 'thisWeek':
        startDate = getWeekStartDate();
        endDate = getCurrentDate();
        break;
      case 'thisMonth':
        startDate = getMonthStartDate();
        endDate = getCurrentDate();
        break;
      case 'thisYear':
        startDate = getYearStartDate();
        endDate = getCurrentDate();
        break;
      case 'custom':
        startDate = customDateRange.start;
        endDate = customDateRange.end;
        break;
      default:
        startDate = endDate = getCurrentDate();
    }

    // Update state
    setCalculatedStart(startDate);
    setCalculatedEnd(endDate);

    // Return the start and end dates in an object
    console.log("Returning from calculate: ", { startDate, endDate })
    return { startDate, endDate };
  };


  // Helper function to get the current date in yyyymmdd format
  const getCurrentDate = () => {
    const currentDate = new Date();
    return currentDate.toISOString().slice(0, 10).replace(/-/g, '');
  };

  // Helper function to get yesterday's date in yyyymmdd format
  const getYesterdayDate = () => {
    const yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    return yesterdayDate.toISOString().slice(0, 10).replace(/-/g, '');
  };

  // Helper function to get the start date of the current week in yyyymmdd format
  const getWeekStartDate = () => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - dayOfWeek);
    return startDate.toISOString().slice(0, 10).replace(/-/g, '');
  };

  // Helper function to get the start date of the current month in yyyymmdd format
  const getMonthStartDate = () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    return startDate.toISOString().slice(0, 10).replace(/-/g, '');
  };

  // Helper function to get the start date of the current year in yyyymmdd format
  const getYearStartDate = () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    return startDate.toISOString().slice(0, 10).replace(/-/g, '');
  };


  const [allSelectedBrandIds, setAllSelectedBrandIds] = useState([]);

  const handleChange = (selectedValues) => {
    if (selectedValues.includes('all')) {
      setSelectedOption(['all']);
      // Load all brand IDs here
      const allBrandIds = brands.map(brand => brand.id);
      // Perform any necessary actions with allBrandIds, e.g., fetching data, updating state, etc.
      // For example, you can set another state variable to store all brand IDs:
      setAllSelectedBrandIds(allBrandIds);
    } else {
      const selectedClientIds = selectedValues.filter(value => clients.some(client => client.id === value));
      const selectedBrandIds = selectedValues.filter(value => brands.some(brand => brand.id === value));

      const clientBrandIds = selectedClientIds.flatMap(clientId => {
        const client = clients.find(client => client.id === clientId);
        return client ? client.brandIds : [];
      });

      const uniqueBrandIds = [...new Set([...selectedBrandIds, ...clientBrandIds])];
      setSelectedOption(uniqueBrandIds);
      // Clear allSelectedBrandIds state when 'all' is not selected
      setAllSelectedBrandIds([]);
    }
  };


  const [clients, setClients] = useState([]);

  useEffect(() => {
    // Fetch clients from the server when the component mounts
    fetchClients();
  }, []);


  const fetchClients = async () => {
    try {
      const response = await getClients();
      console.log(`Fetched clients: `, response);
      setClients(response);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };


  const exportData = () => {
    const exportArray = [];
    const labels = getAllDatesBetween(customDateRange.start, customDateRange.end);

    // Determine the selected brands
    let selectedBrandIds;
    if (selectedOption.includes('all')) {
      selectedBrandIds = brands.map(brand => brand.id);
    } else {
      const selectedClientIds = selectedOption.filter(value => clients.some(client => client.id === value));
      const selectedBrandIdsDirect = selectedOption.filter(value => brands.some(brand => brand.id === value));

      const clientBrandIds = selectedClientIds.flatMap(clientId => {
        const client = clients.find(client => client.id === clientId);
        return client ? client.brandIds : [];
      });

      selectedBrandIds = [...new Set([...selectedBrandIdsDirect, ...clientBrandIds])];
    }

    // Add headers to the export array
    exportArray.push([
      "Date", "Brand", "Messages Billable", "Flagged", "Automated", "AI Errors", "Flagged (%)", "Messages Ready (%)", "Automated (%)", "Errors (%)"
    ]);

    labels.forEach(label => {
      exportArray.push([`Date: ${label}`]);
      selectedBrandIds.forEach(option => {
        const brandData = analytics[option] || {};
        const brand = brands.find(b => b.id === option);
        const brandName = brand ? `${brand.name} (${option})` : option;

        const totalMessages = brandData['messages_billable']?.[label] || 0;
        const totalFlagged = brandData['flagged']?.[label] || 0;
        const totalAutomated = brandData['automated']?.[label] || 0;
        const totalErrors = brandData['ai_error']?.[label] || 0;

        const flagRatio = (totalMessages !== 0) ? Math.round((totalFlagged / totalMessages) * 1000) / 10 : 0.1;
        const messagesReadyRatio = 100 - flagRatio;
        const automateRatio = (totalMessages !== 0) ? Math.round((totalAutomated / totalMessages) * 1000) / 10 : 0.1;
        const errorRatio = (totalMessages !== 0) ? Math.round((totalErrors / totalMessages) * 1000) / 10 : 0.1;

        exportArray.push([
          "", // Empty cell for Date column
          brandName,
          totalMessages,
          totalFlagged,
          totalAutomated,
          totalErrors,
          flagRatio,
          messagesReadyRatio,
          automateRatio,
          errorRatio
        ]);
      });
      exportArray.push([]); // Add an empty row for better readability
    });

    const worksheet = XLSX.utils.aoa_to_sheet(exportArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Analytics");

    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'analytics.xlsx');
  };


  const filterOption = (input, option) => {
    if (option.children) {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
    return false;
  };


  return (
    <>
      <div className='switch2'>
        <p>Select a brand or multiple brands to see analytics</p>
        {/* <p>Currently viewing: {currentBrand.name}</p>
        <p>Brand ID: {currentBrand.id}</p> */}

        {brands ? (
          // <Select
          //   mode="multiple"
          //   defaultValue={["Select brand"]}
          //   style={{ width: `80%`, marginTop: '10px' }}
          //   value={selectedOption}
          //   onChange={handleChange}
          // >
          //   <OptGroup label="All brands">
          //     <Option value="all">All</Option>
          //   </OptGroup>
          //   <OptGroup label="Clients">
          //     {clients.map(client => (
          //       <Option key={client.id} value={client.id}>{client.name}</Option>
          //     ))}
          //   </OptGroup>
          //   <OptGroup label="Brands">
          //     {brands.map(brand => (
          //       <Option key={brand.id} value={brand.id}>{brand.name}</Option>
          //     ))}
          //   </OptGroup>
          // </Select>

          <Select
            mode="multiple"
            style={{ width: `80%`, marginTop: '10px' }}
            value={selectedOption}
            onChange={handleChange}
            showSearch
            filterOption={filterOption}
            optionFilterProp="children"
          >
            <OptGroup label="All brands">
              <Option value="all">All</Option>
            </OptGroup>
            <OptGroup label="Clients">
              {clients.map(client => (
                <Option key={client.id} value={client.id}>{client.name}</Option>
              ))}
            </OptGroup>
            <OptGroup label="Brands">
              {brands.map(brand => (
                <Option key={brand.id} value={brand.id}>{brand.name}</Option>
              ))}
            </OptGroup>
          </Select>
        ) : null}
      </div>

      <div className='stats'>
        <div className='box'>
          <div className='text'>
            <div className='text-main'>
              <h1 className='main-text'>{messagesNoFlag}</h1>
              <p className='change' id='color-indicator'>{messagesNoFlagRatio}%</p>
              <p className='descriptor'>Messages ready</p>
            </div>
            <div className='trend' id='color-indicator2'>
              <div className='centered'>
                <FaSortUp id='color-indicator' />
              </div>
            </div>
          </div>
        </div>
        <div className='box'>
          <div className='text'>
            <div className='text-main'>
              <h1 className='main-text'>{flagged}</h1>
              <p className='change' id='color-indicator'>{flagRatio}%</p>
              <p className='descriptor'>Flagged</p>
            </div>
            <div className='trend' id='color-indicator2'>
              <div className='centered'>
                <FaSortUp id='color-indicator' />
              </div>
            </div>
          </div>
        </div>
        <div className='box'>
          <div className='text'>
            <div className='text-main'>
              <h1 className='main-text'>{automated}</h1>
              <p className='change' id='color-indicator'>{automateRatio}%</p>
              <p className='descriptor'>Automated</p>
            </div>
            <div className='trend' id='color-indicator2'>
              <div className='centered'>
                <FaSortUp id='color-indicator' />
              </div>
            </div>
          </div>
        </div>
        <div className='box'>
          <div className='text'>
            <div className='text-main'>
              <h1 className='main-text'>{errors}</h1>
              <p className='change' id='color-indicator-2'>{errorRatio}%</p>
              <p className='descriptor'>Errors</p>
            </div>
            <div className='trend' id='color-indicator2-2'>
              <div className='centered'>
                <FaSortUp id='color-indicator-2' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Select
        className='specific-selector'
        showSearch
        placeholder="Today"
        optionFilterProp="children"
        onChange={onChange}
        options={graphOptions}
        style={{ width: '175px' }}
      />


      {custom && (
        <Space className='rangePicker' direction="vertical" size={12}>
          <RangePicker onChange={handleDateRangeChange} />
        </Space>
      )}

      <Button onClick={exportData} type="primary" style={{ margin: '10px' }}>
        Export
      </Button>

      <div className='graph-container'>
        <div className='h-bar'>
          <h2 className='h2'>Billable messages</h2>
          <h1 className='h1'>{messages} <span className='span'>{0}</span></h1>
        </div>
        <div className='big-graph'>
          <Bar className='bar' data={graphData} options={settingOptions}></Bar>
        </div>
      </div>
    </>
  )
}

export default Charts