// require('dotenv').config()
import styles from './popup.module.css';
import React, { useEffect, useState, useRef  } from 'react'
import { useCustomer } from '../../context/CustomerProvider';
import { Modal, Button, Input, Alert } from 'antd';
import { Divider, Space, Tag, Select, Switch } from 'antd';
import ReactDOM from 'react-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { FaCopy } from "react-icons/fa";
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import debounce from 'lodash/debounce'; // import debounce from lodash library
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Dropzone from "react-dropzone";
import { htmlToText } from 'html-to-text';
// import ReactHtmlParser from 'html-react-parser';


const { TextArea } = Input;
const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

const Popup = () => {

  let { id } = useParams();

    const { sendEmail, applyLoading } = useCustomer()
    const { deleteEmail, updateAgent, updateNotepad } = useCustomer()
    const { record, getEmailObj, loading, setEmailObj } = useCustomer()
    const {agentId, setAgentId } = useCustomer()
    const {notepad, setNotepad } = useCustomer()
    const {alreadySent, setAlreadySent} = useCustomer()

    const textAreaRef = useRef(null);

    const [subject, setSubject] = useState(null);
    const [content, setContent] = useState(null);
    const [senderEmail, setSenderEmail] = useState(null);

    // New ones because customoerProvider is causing me issues
    const [record2, setRecord2] = useState(null)
    const [sent, setSent] = useState(null)

    const [error, setError] = useState(false);

    const [editorHtml, setEditorHtml] = useState('');

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [attachments, setAttachments] = useState([]);

    useEffect(() => {
      console.log("I'm here!")
      console.log("in Popup with the record id ", id)

      async function getData() {
        const fetchedData = await getEmailObj(id);
        setEmailObj(fetchedData)
        console.log("fetched data: ", fetchedData)
        setRecord2(fetchedData)
        console.log("fetched wasSent ", fetchedData)
        setSent(fetchedData.wasSent)
      }
      getData();
    }, [])


    const handleSend = async () => {
      setError(false)
      //might need more logic here
      console.log("was sent: ", record.wasSent)

      console.log("right before as html: ", editorHtml)

      // Replace all <p> tags with newline characters
      const plainText = editorHtml.replace(/<\/?p>/gi, '\n')
      // Convert HTML to plain text
      .replace(/<\/?[^>]+(>|$)/g, '');

      const res = await sendEmail(record.id, subject, editorHtml, plainText, record.wasEdited, senderEmail, record.emailThreadId, record.date_sent, attachments);
      console.log("Trying to send email response: ", res);

      if (!res || res.success === false) {
        console.log("Should be setting setError to true");
        // console.log("was sent2: ", record.wasSent);
        setError(true);
        alert('Error sending email: ' + res.message);
      } else {
        // console.log("was sent3: ", record.wasSent);
        setSent(true);
        alert('Email sent successfully. ');
      }

      // const res = await sendEmail(record.id, subject, editorHtml, plainText, record.wasEdited, senderEmail, record.emailThreadId, record.date_sent, attachments)
      // console.log("Trying to send email response: ", res)
      // if (!res) {
      //   console.log("should be setting setError to true")
      //   console.log("was sent2: ", record.wasSent)
      //   setError(true)
      //   alert('ERROR sending email.');
      // } else {
      //   console.log("was sent3: ", record.wasSent)
      //   setSent(true)
      //   alert('Email sent successfully. ', record.wasSent);
      // }
    }

    const handleDelete = async () => {
      setError(false)
      //might need more logic here
      const res = await deleteEmail(record.id)
      console.log(res)
      if (!res) {
        setError(true)
        alert("ERROR: Failed to delete email.")
      } else {
        alert('Email deleted successfully.');
      }
    }

  // Update the notepad state whenever the text changes
  function handleNotepadChange(event) {
    console.log("text: ", event.target.value)
    setNotepad(event.target.value);
    // saveNotepad(event.target.value); // Call the debounced function to save the text
  }

    const handleBlur = async () => {
      try {
        console.log("on blur")
        const res = await updateNotepad(record.id, notepad)
        console.log("Successfully wrote the note to the database ", res)
        if (!res) {
          setError(true)
        }
      } catch (error) {
        console.log('Error saving note to database:', error);
        setError(true)
      }
    };

    useEffect(() => {
      window.addEventListener('beforeunload', handleUnload);
      return () => {
        window.removeEventListener('beforeunload', handleUnload);
      };
    }, []);

    useEffect(() => {
      window.addEventListener('pagehide', handlePageHide);
      return () => {
        window.removeEventListener('pagehide', handlePageHide);
      };
    }, []);


    const handlePageHide = async (event) => {
      try {
        console.log("handle page hide")
        const res = await updateNotepad(record.id, notepad)
        console.log("Successfully wrote the note to the database ", res)
        if (!res) {
          setError(true)
        }
      } catch (error) {
        console.log('Error saving note to database:', error);
        setError(true)
      }
    };

  
    const handleUnload = async (event) => {
      event.preventDefault();
      event.returnValue = '';
  
      try {
        console.log("handle unload")
        console.log(record.id)
        console.log(notepad)
        const res = await updateNotepad(record.id, notepad)
        console.log("Successfully wrote the note to the database ", res)
        if (!res) {
          setError(true)
        }
      } catch (error) {
        console.log('Error saving note to database:', error);
        setError(true)
      }
    };


    useEffect(() => {
      if(record) {
        setSubject(record.generated_subjectLine)
        setContent(record.generated_contents)

        let generatedHtml = ``;

        if (record.generated_contents === null || record.generated_contents === undefined) {
          // code to execute if generated_contents is null or undefined
          generatedHtml = `<p style="white-space: pre-wrap">${record.generated_contents}</p>`;
        } else {
          generatedHtml = `<p style="white-space: pre-wrap">${record.generated_contents.trim()}</p>`;
        }

        setEditorHtml(generatedHtml)
        setSenderEmail(record.from_email)
        try {
          setNotepad(record.notes)
        } catch (error) {
          console.log(error)
          console.log("Catching error trying to set record.notes, didn't exist for a lot of early emails.")
        }
      }
    }, [record])

    useEffect(() => {
      if(record) {
        if(subject !== record.generated_subjectLine || content !== record.generated_contents) {
          record["wasEdited"] = true
        }
        else {
          record["wasEdited"] = false
        }
      }
    }, [record, subject, content])



    const handleChange = async (value) => {
      console.log(`selected value is: ${value}`);
      console.log("all of the data is: ", selectorTeam.options)
      setAgentId(value)
      const res = await updateAgent(record.id, value)
      console.log(res)
      if (!res) {
        setError(true)
      }
    };


    const getValue = () => {
      for (let i = 0; i < selectorTeam.options.length; i++) {
        if (selectorTeam.options[i].value === agentId) {
          return selectorTeam.options[i].label;
        }
      }
      return "Assign agent";
    };


    const copyToClipboard = (id) => {
      alert(`localhost:3000/e/${id}`);
      const url = "localhost:3000/e/" + id;
      navigator.clipboard.writeText(url.select());
    };

    const { selectorTeam, setSelectorTeam, getTeam2 } = useCustomer()

    useEffect(() => {
      console.log("getting team for selector on load")
      callTeam();
    }, []);

    async function callTeam(){
      try {
        const res = await getTeam2();
        console.log("res: ", res)
      } catch (error) {
        console.log(error)
      }
    }


    const presetChange = async (value) => {
      console.log(`selected value is: ${value}`);
      setContent(value.trim())

      const generatedHtml = `<p style="white-space: pre-wrap">${value.trim()}</p>`;
      setEditorHtml(`<p>${generatedHtml}</p>`)
    };


    // const MySelect = async () => {
    //   const defaultValue = await getValue();
    
    //   return (
    //     <Select
    //       defaultValue={defaultValue}
    //       style={{
    //         width: 200,
    //       }}
    //       onChange={handleChange}
    //       options={options}
    //     />
    //   );
    // };
    
    // return <MySelect />;


    // const handleImageUpload = (event) => {
    //   const file = event.target.files[0];
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     console.log("set image src: ", reader.result)
    //     setImageSrc(reader.result);
    //     const range = this.quill.getSelection();
    //     this.quill.insertEmbed(range.index, 'image', reader.result);
    //   };
    //   reader.readAsDataURL(file);
    // };


    // function handleFileSelect(event) {
    //   const files = Array.from(event.target.files);
    //   setSelectedFiles(files);
    // }
  
    function handleFileUpload() {
      // Your code to handle the file upload process goes here.
      // You can access the selected files using the `selectedFiles` state variable.
      console.log("attachments go here! ", attachments)
    }


    function handleDrop(files) {
      const newAttachments = [];
    
      for (const file of files) {
        const img = new Image();
        img.src = URL.createObjectURL(file);
    
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
    
          let maxWidth = 1000;
          let maxHeight = 1000;
    
          // calculate the new dimensions while maintaining the aspect ratio
          let width = img.width;
          let height = img.height;
    
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
    
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
    
          // set the canvas size to the new dimensions
          canvas.width = width;
          canvas.height = height;
    
          // draw the image onto the canvas at the new size
          ctx.drawImage(img, 0, 0, width, height);
    
          // get the resized image as a data URL
          const resizedImage = canvas.toDataURL(file.type, 1);
    
          newAttachments.push({
            filename: file.name,
            content: resizedImage.split(";base64,").pop(),
            encoding: "base64"
          });
    
          setAttachments([...attachments, ...newAttachments]);
        };
      }
    }





    const modules = {
      toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
        {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
    };
  
    const formats = [
      'header', 'font', 'size',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ];
  
    const handleChange4 = (html) => {
      setEditorHtml(html);
    };

    const minHeight = '120px';


    return (
      <>
      {record2 !== null ? (
        <div className={styles.datadiv}>
          <div className={styles.left}>

          <div className={styles.top}>
            {sent && <Alert style={{color: "black"}} message="Successfully sent" type="success" showIcon />}
            {error  && <Alert style={{color: "black"}} message="Unable to send email" type="error" showIcon />}
          </div>


          {record2.ai_data && (
            <>
              <Switch
                className={styles.switch}
                checkedChildren="AI"
                checked={true}
              />
              <div className={styles.aiObj}>
                <div className={styles.objects} onClick={() => record2.ai_data.url && window.open(record2.ai_data.url, '_blank')}>
                  {record2.ai_data.type === 'chirpish' ? (
                    <img className={styles.img} src="/chirpish.png" alt="Chirpish" />
                  ) : (
                    <img className={styles.img} src="/shopify.png" alt="Shopify" />
                  )}
                  <div className={styles.textContainer}>
                    <p className={record2.ai_data.success ? styles.badge : styles.badge2}>
                        {record2.ai_data.success ? 'Success' : 'Failed'}
                      </p>

                    {/* <p>{record2.ai_data.date}</p> */}
                    <p className={styles.pbold}>{record2.ai_data.title}</p>
                    <p className={styles.pgray}>{record2.ai_data.message}</p>
                  </div>
                </div>
              </div>
            </>
          )}


            <div className={styles.datacontent}>
              <div className={styles.horizontal}>
                <h1 className={styles.modalHeading}>{record2.from_subjectLine}</h1>
                <br></br>

                {/* <MySelect></MySelect> */}
                <Select
                  defaultValue={getValue()}
                  style={{
                    width: 200,
                  }}
                  onChange={handleChange}
                  options={[selectorTeam]}
                />

                {/* <CopyToClipboard className={styles.copy} text={`https://app.trysynergic.com/e/${record.id}`}><button><FaCopy className={styles.icon}/></button></CopyToClipboard> */}
              </div>
              <p>Name: {record2.from_name}{/* <span className={styles.weightText}>Date: </span>{(new Date(record.date_sent)).toLocaleTimeString("en-US", options)} */}</p>
              <p><span className={styles.weightText}></span>From: {record2.from_email}</p>
              <p><span className={styles.weightText}></span>Date: {(new Date(record2.date_sent)).toLocaleTimeString("en-US", options)}</p>
              <br/>

              {record2.attachments == [] || record2.attachments == undefined ? (
                <>
                  {/* <a href='user/test.mp4' target="_blank">Image1</a> */}
                </>
              ) : (
                <>
                  {record2.attachments.map((path, index) => (
                    // <p key={index}>{string}</p>
                    <a className={styles.links} href={path} target="_blank" rel="noopener noreferrer">Image{index}</a>
                  ))}
                </>
              )}
              <br></br><br></br>

              <TextArea className={styles.disabled} rows={10} value={record2.from_contents} />
              {/* <p className={styles.from_content}>{record.from_contents}</p> */}
              <br/><br/><br/>
              {/* <br></br> */}
              <h1 className={styles.modalHeading}>AI Response</h1>
              <p>
                {(new Date(record.date_responded)).toLocaleTimeString("en-US", options)}
              </p>
              <br></br>
              {sent ? <TextArea className={styles.disabled} rows={1} value={record2.from_email} /> : 
              <TextArea rows={1} value={senderEmail} onChange={(e) => setSenderEmail(e.target.value)}/> }
              <br/><br/>
              {sent ? <TextArea className={styles.disabled} rows={1} value={record2.return_subjectLine} /> : 
              <TextArea rows={1} value={subject} onChange={(e) => setSubject(e.target.value)}/> }
              <br/><br/>
              
              {/* <input type="file" onChange={handleImageUpload} />
              {imageSrc && (
                <div>
                  <img src={imageSrc} alt="uploaded" />
                </div>
              )} */}

              {/* <div>
                <input type="file" multiple onChange={handleFileSelect} />
                <button onClick={handleFileUpload}>Upload Files</button>
              </div> */}

              <div className={styles.images}>
                <Dropzone onDrop={handleDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button>Choose files</Button>
                      {/* <p>Drag and drop files here, or click to select files</p> */}
                    </div>
                  )}
                </Dropzone>
                <ul>
                  {attachments.map((attachment) => (
                    <li key={attachment.filename}>{attachment.filename}</li>
                  ))}
                </ul>
                {/* <button onClick={handleFileUpload}>Upload Files</button> */}
              </div>
              
              <ReactQuill 
                className={styles.quill}
                value={record2.wasSent ? record2.return_contents : editorHtml} 
                onChange={handleChange4} 
                modules={modules}
                formats={formats}
                style={{ minHeight }} // Pass the style object here
              />

              {/* {sent ? <TextArea className={styles.disabled} rows={15} value={record2.return_contents} /> :
              <TextArea rows={15} className={styles.enabled} value={content} onChange={(e) => setContent(e.target.value)}/>
              } */}

              <div className={styles.buttons}>
                {/* <Select
                  className={styles.select_left}
                  defaultValue={"Presets"}
                  showSearch
                  style={{
                    width: 200,
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label.toLowerCase() ?? '').includes(input.toLowerCase()) || option?.label.toLowerCase() === 'cancelled'
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onChange={presetChange}
                  options={option}
                /> */}
                <Button className={styles.button} key="1" type="primary" onClick={() => handleDelete()} disabled={sent}>Delete</Button>
                <Button className={styles.button} key="2" type="primary" onClick={() => handleSend()} loading={applyLoading} disabled={sent}>Send email</Button>
              </div>
              <br/><br/>
              <div className={styles.top}>
                {sent && <Alert style={{color: "black"}} message="Successfully sent" type="success" showIcon />}
                {error  && <Alert style={{color: "black"}} message="Unable to send email" type="error" showIcon />}
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <p>Notepad</p>
            <div className={styles.notepad}>
              <TextArea rows={20} className={styles.enabled} value={notepad} onChange={handleNotepadChange} onBlur={handleBlur}></TextArea>
            </div>

            <div className={styles.ai}>

            </div>

          </div>
        </div>
        ) : (
          <p>Loading...</p>
        )}
      </>
    )
}

export default Popup