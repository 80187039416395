import React from 'react'
import "./email.css"
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import { Button, Space } from 'antd';
import { useCustomer } from '../../context/CustomerProvider';
import Popup from '../Popup';


const Email = () => {
    // const [order, setVariable] = useState({})

    let { id } = useParams();
    const { emailObj, getEmailObj, loading } = useCustomer()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [record, setRecord] = useState(null);

    const showModal = (record) => {
        console.log("clicking showModal")
        setRecord(record)
        setIsModalOpen(true);
      };

    useEffect(() => {
      console.log("id, ", id)
      getEmailObj(id)
    }, [])


    //call api function from api client
    const handleEmail = async () => {
        try {
          //
        } catch {
          //
        }
      }

    return (
        <div>
              <button className='b' onClick={() => showModal(emailObj)}>See email</button>
              <Popup record={emailObj} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
        </div>
    )
}

export default Email