import { useParams, useLocation } from 'react-router-dom';
import {useEffect, useState} from 'react';
import './invite.css'
import { useCustomer } from '../../context/CustomerProvider';
import { useAuth } from '../../context/AuthProvider';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function Invite() {
  const queryParams = new URLSearchParams(window.location.search);

	const { currentUser } = useAuth()
	const { setUser, acceptInvite } = useCustomer();
  const code = queryParams.get('code');
  const stamp = queryParams.get('timestamp');
	const [cardMessage, setCardMessage] = useState('');


	const history = useHistory();

	const confirmInvite = async (uid, brandId, stamp) => {
		try{
			const res = await acceptInvite(uid, brandId, stamp);
			console.log(res);
			setCardMessage(res.message)
		}catch(error){
			console.error(error);
		}
	}


	useEffect(() => {
		localStorage.clear();
		// Made a manual way of redirecting unauthenticated users since I have to change
		// <PrivateRoute path="/invite" component={Invite} exact/> to just <Route path="/invite" component={Invite} exact/>
		// Reason for the change is if authenticated user gets redirected to their invite link, this component does not render properly (it just shows a black screen)
		if(currentUser === null && (code && stamp)){
			console.log("Here");
			localStorage.setItem("item", JSON.stringify({code, stamp}))
			console.log(JSON.parse(localStorage.getItem("item")))

			// Can be any private route here, I'm trying to force a check for authentication in App.js so they get redirected to '/login' anyway
			history.push('/'); 
		}else if(code === null || stamp === null){
			console.log("Here");
			history.push('/')
		}else {
			confirmInvite(currentUser.uid, code, stamp)
		}
	}, [])

	const redirect = () => {
		history.push('/login')
	}

  return (	
		<div className="container">
				<div className="invite-card">
					<h3 className="title">{cardMessage}</h3>
					<button className="sub" style={{padding: '20px', fontSize: "24px"}} onClick={redirect}>Proceed To Dashboard</button>
				</div>
		</div>
  );
}

export default Invite;
