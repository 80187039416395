// require('dotenv').config()
import React from 'react'
import "./privacy.css"

export default function Privacy(){

    return (
        <>
        <div className='privacy'>
            <h1 className='privacy-h'>Privacy policy</h1>

            <pre>
            {`
            Protecting your private information is our priority. This Statement of Privacy applies to chirpish.ai,
            and Chirpish LLC and governs data collection and usage. For the purposes of this Privacy Policy,
            unless otherwise noted, all references to Chirpish LLC include chirpish.ai and Chirpish. The
            Chirpish website is a Customer Service agency powered by AI site. By using the Chirpish website,
            you consent to the data practices described in this statement.

            Collection of your Personal Information

            In order to better provide you with products and services offered, Chirpish may collect personally
            identifiable information, such as your:

            - First and Last Name
            - E-mail Address
            - Google Account Information (for authentication)
            - Gmail Profile Data (for email data retrieval)

            Please keep in mind that if you directly disclose personally identifiable information or personally
            sensitive data through Chirpish's public message boards, this information may be collected and
            used by others.

            We do not collect any personal information about you unless you voluntarily provide it to us.
            However, you may be required to provide certain personal information to us when you elect to use
            certain products or services. These may include: (a) registering for an account; (b) entering a
            sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers
            from selected third parties; (d) sending us an email message; (e) submitting your credit card or
            other payment information when ordering and purchasing products and services. To wit, we will
            use your information for, but not limited to, communicating with you in relation to services and/or
            products you have requested from us. We also may gather additional personal or non-personal
            information in the future.

            Use of your Personal Information

            Chirpish collects and uses your personal information to operate and deliver the services you have
            requested. This includes the use of Google data for:

            1. Authentication purposes.
            2. Retrieving email data and account data required to operate our CRM, allowing us to personalize
            emails, monitor incoming emails using webhooks, and display email data accurately.

            Chirpish may also use your personally identifiable information to inform you of other products or
            services available from Chirpish and its affiliates.

            Sharing Information with Third Parties

            Chirpish does not sell, rent, or lease its customer lists to third parties.
            Chirpish may share data with trusted partners to help perform statistical analysis, send you email or
            postal mail, provide customer support, or arrange for deliveries. All such third parties are
            prohibited from using your personal information except to provide these services to Chirpish, and
            they are required to maintain the confidentiality of your information.

            Chirpish may disclose your personal information, without notice, if required to do so by law or in
            the good faith belief that such action is necessary to:

            (a) Conform to the edicts of the law or comply with legal process served on Chirpish or the site.
            (b) Protect and defend the rights or property of Chirpish; and/or
            (c) Act under exigent circumstances to protect the personal safety of users of Chirpish or the public.

            Automatically Collected Information

            Information about your computer hardware and software may be automatically collected by
            Chirpish. This information can include: your IP address, browser type, domain names, access
            times, and referring website addresses. This information is used for the operation of the service, to
            maintain quality of the service, and to provide general statistics regarding use of the Chirpish
            website.

            Use of Cookies

            The Chirpish website may use "cookies" to help you personalize your online experience. A cookie
            is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run
            programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only
            be read by a web server in the domain that issued the cookie to you.

            One of the primary purposes of cookies is to provide a convenience feature to save you time. The
            purpose of a cookie is to tell the Web server that you have returned to a specific page. For
            example, if you personalize Chirpish pages or register with Chirpish site or services, a cookie
            helps Chirpish to recall your specific information on subsequent visits. This simplifies the process of
            recording your personal information, such as billing addresses, shipping addresses, and so on.
            When you return to the same Chirpish website, the information you previously provided can be
            retrieved, so you can easily use the Chirpish features that you customized.

            You have the ability to accept or decline cookies. Most Web browsers automatically accept
            cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you
            choose to decline cookies, you may not be able to fully experience the interactive features of the
            Chirpish services or websites you visit.

            Security of your Personal Information

            Chirpish secures your personal information from unauthorized access, use, or disclosure. Chirpish
            uses the following methods for this purpose:

            - SSL Protocol
            - Encryption for the transmission of personal information, such as credit card numbers.

            We strive to take appropriate security measures to protect against unauthorized access to or
            alteration of your personal information. Unfortunately, no data transmission over the Internet or any
            wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect
            your personal information, you acknowledge that:

            (a) There are security and privacy limitations inherent to the Internet, which are beyond our control;
            and
            (b) Security, integrity, and privacy of any and all information and data exchanged between you and
            us through this Site cannot be guaranteed.

            Right to Deletion

            Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:

            - Delete your personal information from our records; and
            - Direct any service providers to delete your personal information from their records.

            Please note that we may not be able to comply with requests to delete your personal information if
            it is necessary to:

            - Complete the transaction for which the personal information was collected.
            - Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or
            prosecute those responsible for that activity.
            - Debug to identify and repair errors that impair existing intended functionality.
            - Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech,
            or exercise another right provided for by law.
            - Comply with the California Electronic Communications Privacy Act.
            - Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest
            that adheres to all other applicable ethics and privacy laws when our deletion of the information
            is likely to render impossible or seriously impair the achievement of such research, provided we
            have obtained your informed consent.
            - Enable solely internal uses that are reasonably aligned with your expectations based on your
            relationship with us.
            - Comply with an existing legal obligation; or
            - Otherwise use your personal information, internally, in a lawful manner that is compatible with
            the context in which you provided the information.

            Changes to this Statement

            Chirpish reserves the right to change this Privacy Policy from time to time. We will notify you
            about significant changes in the way we treat personal information by sending a notice to the
            primary email address specified in your account, by placing a prominent notice on our website,
            and/or by updating any privacy information. Your continued use of the website and/or Services
            available after such modifications will constitute your:

            (a) Acknowledgment of the modified Privacy Policy; and
            (b) Agreement to abide and be bound by that Policy.

            Contact Information

            Chirpish welcomes your questions or comments regarding this Statement of Privacy. If you believe
            that Chirpish has not adhered to this Statement, please contact Chirpish at:

            Chirpish LLC
            1475 NE 121st St. #410
            North Miami, Florida 33161

            Email Address:
            hello@chirpish.ai

            Telephone number:
            2138202172

            Effective as of Sept 15, 2023
            `}
        </pre>
        </div>
        </>
    )
}