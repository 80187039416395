import React from 'react'
import TableContainer from '../../component/TableContainer'
import Charts from "./Charts"
import NotificationCenter from '../../component/NotificationCenter'
import Header from '../../component/Header'
import Menu from '../../component/Menu'
// import "./dashboard.css" .....

const Analytics = () => {
    return (
        <>
            {/* <Charts></Charts> */}
            <Menu logic={<Charts/>} page={"Analytics"} description={"Performance data analytics to further improve the customer experience."}/>
        </> 
    )
}

export default Analytics