import styles from './login.module.css';
import './login.css';
import React, { useState, useEffect } from "react"
import { useNylas } from '@nylas/nylas-react';
import { Button, Form, Input, Alert, notification } from 'antd';
import { GrCircleAlert } from 'react-icons/gr';
import { BiErrorCircle } from 'react-icons/bi';
import { useAuth } from "../../context/AuthProvider"
import { useHistory, useLocation, Link } from "react-router-dom"
import { useCustomer } from '../../context/CustomerProvider';

export default function Login() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [firstName, setFirstName] = useState("");
  const { firebase, auth, currentUser, login, googleAuth, resetPassword, brandId, signup, fetchCurrentBrand } = useAuth()
  const { setUser, sendSlackMessage } = useCustomer()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const nylas = useNylas();



  // Access query parameters from URL
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Extract query parameters and set default values to null
  const fullName = queryParams.get("full_name") || null;
  const companyName = queryParams.get("company_name") || null;
  const lmref = queryParams.get("lmref") || null;
  const emailAddress = queryParams.get("email") || null;

  const openPopup = (message, description, icon, bg) => {
    notification.open({
      message,
      description,
      icon,
      placement: 'topRight',
      style: {'background-color' : bg}
    })
  }


  useEffect(() => {
    console.log("On Login page but already authenticated ")
    if (currentUser !== null){
      history.push('/');
    }
  }, []);


  useEffect(() => {
    // You can now use the extracted query parameter values (fullName, companyName, lmref) as needed.
    console.log("Full Name:", fullName);
    console.log("Company Name:", companyName);
    console.log("LMREF:", lmref);
    console.log("Email: ", emailAddress);

  }, [fullName, companyName, lmref, emailAddress]);


  // This is the submit of the login form so we'll create user after this step if the user doesn't already exist
  async function handleSubmit() {
    setLoading(true);
    setError("");
  
    try {
      // Check if the user already exists with the provided email
      const methods = await firebase.auth().fetchSignInMethodsForEmail(email);
      console.log("methods are: ", methods);
  
      if (methods.length > 0) {
        // User already exists, attempt to log in
        await login(email, password);
        history.push("/");
      } else {
        // User doesn't exist, create a new account
        const userCredential = await signup(email, password);
  
        console.log("userCredential 3: ", userCredential.user.uid);
  
        // Retrieve the referral information from the global 'window' object
        // const lm_data = window.LinkMink('pub_live_2foBDbORbBKHAbQqSJC4').getReferralData();
  
        // Set lm_data to null if it's undefined or null
        // const normalized_lm_data = lm_data || null;
  
        // Now you can use the lm_data as needed in your component
        // console.log('Referral Data: ', normalized_lm_data);
  
        const data = {
          full_name: null,
          contact_email: email,
          affiliate: null,
          lm_data: null
        };
  
        console.log("About to call setUser regular login: ", data);
        await setUser(userCredential.user.uid, data);
  
        history.push("/");
      }
    } catch (error) {
      console.log("Failed to login: ", error);
  
      if (error.code === "auth/email-already-in-use") {
        try {
          await login(email, password);
          history.push("/");
        } catch (error) {
          console.log("embedded error: ", error);
        }
      }
      setError("Failed to log in");
    }
    setLoading(false);
  }


  async function handleSubmitGoogle() {
    try {
      const status = await googleAuth();

      console.log("just returned ", status.user.uid)
      console.log("just returned entire google object: ", status.user)

      console.log("users name: ", status.user.displayName)
      console.log("users email: ", status.user.email)


      // Retrieve the referral information from the global 'window' object
      const lm_data = window.LinkMink('pub_live_2foBDbORbBKHAbQqSJC4').getReferralData();

      // Set lm_data to null if it's undefined or null
      const normalized_lm_data = lm_data || null;
      
      // Now you can use the lm_data as needed in your component
      console.log('Referral Data: ', normalized_lm_data);

      // Save the data sent from the LP to the user
      // const data = {
      //   uid: status.user.uid,
      //   // name: firstName || "Name",
      //   full_name: fullName,
      //   company_name: companyName,
      //   affiliate: lmref,
      //   lm_data: normalized_lm_data,
      //   lp_email: emailAddress
      // }

      const data = {
        full_name: status.user.displayName,
        contact_email: status.user.email,
        affiliate: lmref,
        lm_data: normalized_lm_data
      }

      console.log("About to call setUser: ", status.user.uid, data)
      await setUser(status.user.uid, data)

      // sendSlackMessage(`0/4\nFull Name: ${fullName}\nCompany name: ${companyName}\nEmail address: ${emailAddress}\nAffiliate: ${lmref}\nMessage: Successfully signed up.`)

          // send to onboarding after authorized
      // history.push("/o")
      const codeAndStamp = JSON.parse(localStorage.getItem('item'))
      // If localstorage contains code and timestamp from invite link
      // navigate back to /invite after logging in
      if(codeAndStamp){
        const {code, stamp} = codeAndStamp;
        history.push(`/invite/?code=${code}&timestamp=${stamp}`)
      }else{
        history.push("/") // ?demo=true
      }
  
      // console.log("brandId in Login.js is: ", brandId) // added

    } catch(e) {
      console.log("failed to login: ", e)
      setError("Failed to log in")
    }
  }


  const forgotPass = async () => {
    setError("")
    try {
      await resetPassword(email)
    } catch {
      console.log("failed to login")
      setError("Failed to log in")
    }
  };



  const [isLoading, setIsLoading] = useState(false);

  const loginUser = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // sessionStorage.setItem('userEmail', email);

    console.log("nylas is: ", nylas)

    nylas.authWithRedirect({
      emailAddress: email,
      successRedirectUrl: '',
    });
  };

  // console.log(firstName)

  const handleResetPass = async () => {
    console.log('Button clicked!');
    // simple regex pattern for email format validation
    const emailRegex = /\S+@\S+\.\S+/;

    try {
      if (!emailRegex.test(email)) { 
          // if email is invalid, throw an error
          // throw new Error('Invalid email format!');
          openPopup('Invalid Email Format or Email Not Available.', "Please input the email connected to your account and we'll email you a link to reset your password.", <BiErrorCircle/>, '#FF7276 ')
      } else {
        const reset = await resetPassword(email);
        console.log("Reset: ", reset)
  
        openPopup('Reset Password Email Sent!.',`We have send the link to reset your password over at ${email}`, <GrCircleAlert/>, '#EEE')
      }
    } catch (error) {
      console.log(error.message);
      openPopup('User does not exist', error.message , <BiErrorCircle/>, '#FF7276 ')
    }
}

  return (
    <>
      <div className="container-beginning">
        <div className="centered-beginning">
          {/* <img className="logo" src="chirpish.ai-logo.png" alt="Chirpish logo" /> */}
          
              <div className={styles.login}>
                <h3 className={styles.title}>Create an AICS account</h3>
                <p className={styles.parr}>
                  Add AI to your Shopify brand in minutes.
                  {/* <Link to="/login" className={styles.logintext}> Forgot password?</Link> */}
                </p>

                {/* <p className={styles.label}>First Name</p>
                <input 
                  className={styles.input}
                  type="text" 
                  placeholder="Cael"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                /> */}

                {/* <p className={styles.par}></p> */}

                <button className={styles.button} onClick={handleSubmitGoogle}>
                  <div className={styles.buttoncontent}>
                    <img src="google.png" alt="Google logo" className={styles.buttonlogo} />
                    <p className={styles.buttontext}>Sign in with Google</p>
                  </div>
                </button>

                  {/* <p className={styles.par}>or sign in using email and password</p> */}

                  <div className={styles.headerOr}>OR</div>

                  <form
                    className={styles.loginform}
                    name="basic"
                    autocomplete="on"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <p className={styles.label}>Email address</p>
                    <input
                      className={styles.input}
                      type="email"
                      placeholder="cael@chirpish.co"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />

                    <p className={styles.label}>Password</p>
                    <input
                      className={styles.input}
                      type="password"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />

                    {/* onClick={handleSubmit} */}
                    <button type="submit" loading={loading} className={styles.submitbutton}>
                      Get started - for Free
                    </button>
                  </form>

                  <p className={styles.parr}>
                    By signing in, you agree to our{' '}
                    <Link to="/privacy" className={styles.policy}>privacy policy</Link>.
                    If you forgot your password  
                    <button onClick={handleResetPass} className={styles.buttonlink}> click here </button>
                    to reset it.
                  </p>

              </div>

        </div>
      </div>
    </>
  )
}
