import React from 'react'
import TableContainer from '../../component/TableContainer'
import NotificationCenter from '../../component/NotificationCenter'
import Header from '../../component/Header'
import Menu from '../../component/Menu'
import Setting from '../../component/Settings'
// import "./dashboard.css" .....

const Settings = () => {
    return (
        <>
            <Menu logic={<Setting/>} page={"AICS by Merge Labs"} description={"Customize your CX experience, expand your team, enable AI, and more from Settings."}/> 
            {/* "Customize your experience with AICS to optimize the customer experience for your customers." */}
        </> 
    )
}

export default Settings