import "./finetuning.css";
import React, { useState, useEffect } from "react";
import { Button, Form, Input, Alert, Select } from "antd";
import { useAuth } from "../../context/AuthProvider";
import { useCustomer } from "../../context/CustomerProvider";
import axios from "axios";

const { TextArea } = Input;


export default function Finetuning() {

    const [selectedOption, setSelectedOption] = useState('Instructions');
    const [textAreas, setTextAreas] = useState([]);

    const [documentId, setDocumentId] = useState('');
    const [prompt, setPrompt] = useState('');
    const [prompt2, setPrompt2] = useState('');
    const [instructions, setInstructions] = useState('');
    const [thread, setThread] = useState('');
    const [generated, setGenerated] = useState('');

    const handleOptionChange = async (event) => {
        setSelectedOption(event.target.value);
        if(event.target.value === 'Generate'){
            await callGenerate(event.target.value);
        }
    };


    const callGenerate = async (target) => {
        try {
          const response = await axios.post('https://app.mergelabs.co/customer/finetune-generate-get', null, {
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY, // Include the API key in the request headers
            }
          });
          
          const { documentId, prompt, prompt2, instructions, thread, generated } = response.data;
    
        //   setTextAreas([prompt, prompt2, instructions, thread, generated]);
          setDocumentId(documentId);
          setPrompt(prompt);
          setPrompt2(prompt2);
          setInstructions(instructions);
          setThread(thread);
          setGenerated(generated);
    
          console.log('Data retrieved:', response.data);
        } catch (error) {
          console.error('Error retrieving data:', error);
        }
      };


      const saveGenerate = async () => {
        try {
          const response = await axios.post('https://app.mergelabs.co/customer/finetune-generate-save', {
            documentId,
            prompt,
            prompt2,
            instructions,
            thread,
            generated
          }, {
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY, // Include the API key in the request headers
            }
          });
    
          console.log('Data saved:', response.data);
        } catch (error) {
          console.error('Error saving data:', error);
        }
      };


    const handleSendClick = async () => {
        // Implement your logic to send the data to the server here
        console.log('Sending data to the server:', textAreas);
        await saveGenerate();
        await callGenerate();
      };


    const renderTextAreas = () => {
        switch (selectedOption) {
            case 'Generate':
                return (
                  <>
                    <div className="pair">
                      <label>Prompt</label>
                      <TextArea
                        rows={7}
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                      />
                    </div>
              
                    <div className="pair">
                      <label>Prompt2</label>
                      <TextArea
                        rows={7}
                        value={prompt2}
                        onChange={(e) => setPrompt2(e.target.value)}
                      />
                    </div>
              
                    <div className="pair">
                      <label>Instructions</label>
                      <TextArea
                        rows={7}
                        value={instructions}
                        onChange={(e) => setInstructions(e.target.value)}
                      />
                    </div>
              
                    <div className="pair">
                      <label>Thread</label>
                      <TextArea
                        rows={7}
                        value={thread}
                        onChange={(e) => setThread(e.target.value)}
                      />
                    </div>
              
                    <div className="pair">
                      <label>Generated</label>
                      <TextArea
                        rows={7}
                        value={generated}
                        onChange={(e) => setGenerated(e.target.value)}
                      />
                    </div>
                  </>
                );
              
          default:
            return null;
        }
      };

  return (
    <>
        <div className='test'>
            <label htmlFor="options">Select an option: </label>
            <select id="options" value={selectedOption} onChange={handleOptionChange}>
                <option value="Instructions">Instructions</option>
                <option value="Reduce policy">Reduce policy</option>
                <option value="Generate">Generate</option>
                <option value="Commands">Commands</option>
            </select>

            <div className="textareas">
                {renderTextAreas()}
                <Button type="primary" onClick={handleSendClick} className="finetune-button">
                    Save
                </Button>
            </div>
        </div>
    </> 
  )
}