import React from 'react'
import Menu from '../../component/Menu'
import Integrations from '../../component/Integrations/Integrations'

const Integration = () => {
    return (
        <>
            <Menu logic={<Integrations/>} page={"Integrations"} description={"Integrate with your favourite apps and supercharge your ChirpishAI experience!"}/> 
        </> 
    )
}

export default Integration