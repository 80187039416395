import React from 'react'
import Email from '../../component/Email'
import Menu from '../../component/Menu'
// import "./dashboard.css"

const EmailView = () => {
    return (
        <>
                <Menu logic={<Email/>} page={"Email view"} description={"Click the button below to open the specified order."}/>
                {/* <Header />
                <TableContainer/> */}
        </> 
    )
}

export default EmailView