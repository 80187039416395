  import React from "react"
import { Route, Redirect } from "react-router-dom"
import { useAuth } from "../../context/AuthProvider"
import { useEffect } from "react"

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser, brandId, brandIdLoaded } = useAuth()

  useEffect(() => {
    // Log the currentUser and brandId when the component mounts
    // console.log("PRIVATE ROUTER currentUser:", currentUser);
    // console.log("PRIVATE ROUTER brandId:", brandId);
    // console.log("brandIdLoaded: ", brandIdLoaded)

  }, [currentUser, brandId]);


  return (
    <Route
      {...rest}
      render={props => {
        if (currentUser) {
          return <Component {...props} />;
        } else {
            console.log('Redirect to Login Page')
            return <Redirect to="/login" />;
        }
      }}
    />
  );
}



