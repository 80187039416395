import axios from 'axios';
// require('dotenv').config()

// const API_URL =  `http://127.0.0.1:8080`; 
const API_URL = `https://app.mergelabs.co`;   
// const API_KEY = '';
// const API_SECRET = '';

class ApiClient {
    constructor(){
		// this.auth = { username: API_KEY, password: API_SECRET };
		this.baseUrl = API_URL;
	}

	//calls backend to get customer data
    async getCustomerData(body, auth){ 
		const responseData = await this.execute(`post`, `/customer/data`, body, auth);
		return responseData;
	}


    async getCustomerDataRange(body, auth){ 
		const responseData = await this.execute(`post`, `/customer/dataInRange`, body, auth);
		return responseData;
	}

	async paginate(body, auth){ 
		const responseData = await this.execute(`post`, `/customer/paginate`, body, auth);
		return responseData;
	}

	async setFAQ(body, auth){ 
		const responseData = await this.execute(`post`, `/customer/setFAQ`, body, auth);
		return responseData;
	}

	async getAutomations(body, auth){ 
		const responseData = await this.execute(`post`, `/customer/getAutomations`, body, auth);
		return responseData;
	}

	async getFAQ(body, auth){ 
		const responseData = await this.execute(`post`, `/customer/getFAQ`, body, auth);
		return responseData;
	}

	async search(body, auth){ 
		const responseData = await this.execute(`post`, `/customer/search`, body, auth);
		return responseData;
	}

	async searchEmails(body, auth){ 
		const responseData = await this.execute(`post`, `/customer/searchEmails`, body, auth);
		return responseData;
	}

	async setAutomations(body, auth){ 
		const responseData = await this.execute(`post`, `/customer/setAutomations`, body, auth);
		return responseData;
	}

	async getDataWithFilter(body, auth){ 
		const responseData = await this.execute(`post`, `/customer/filter`, body, auth);
		return responseData;
	}

	async getNotificationData(body, auth){
		const responseData = await this.execute(`post`, `/customer/notifications`, body, auth);
		return responseData;
	}

	async uploadFile(body, auth){
		const responseData = await this.execute(`post`, `/customer/upload`, body, auth);
		return responseData;
	}

	async removeFile(body, auth){
		const responseData = await this.execute(`post`, `/customer/removeFile`, body, auth);
	}

	//calls backend to validate an automated response
    async sendEmail(body, auth){ 
		const responseData = await this.execute(`post`, `/customer/send`, body, auth);
		return responseData;
	}

	async deleteEmail(body, auth){
		const responseData = await this.execute(`post`, `/customer/delete`, body, auth)
		return responseData;
	}

	async updateAgent(body, auth){
		const responseData = await this.execute(`post`, `/customer/agent`, body, auth)
		return responseData;
	}

	async updateNotepad(body, auth){
		const responseData = await this.execute(`post`, `/customer/notepad`, body, auth)
		return responseData;
	}

	async getAiData(body, auth){
		const responseData = await this.execute(`post`, `/customer/aiData`, body, auth)
		return responseData;
	}

	async getShopifyData(body, auth){
		const responseData = await this.execute(`post`, `/customer/shopifyData`, body, auth)
		return responseData;
	}

	async getShopifyOrderRefund(body, auth){
		const responseData = await this.execute(`post`, `/customer/refund`, body, auth);
		return responseData;
	}

	async getEmailFiles(body, auth){
		const responseData = await this.execute(`post`, `customer/getFiles`, body, auth);
		return responseData;
	}

	async getEmailObject(body, auth){
		const responseData = await this.execute(`post`, `customer/email`, body, auth);
		return responseData;
	}

	async getEmailObjects(body, auth){
		const responseData = await this.execute(`post`, `customer/bulk`, body, auth);
		return responseData;
	}

	async pagination(body, auth){
		const responseData = await this.execute(`post`, `customer/pagination`, body, auth);
		return responseData;
	}

	async paginationForward(body, auth){
		const responseData = await this.execute(`post`, `customer/pagination/forward`, body, auth);
		return responseData;
	}

	async paginationBackward(body, auth){
		const responseData = await this.execute(`post`, `customer/pagination/backward`, body, auth);
		return responseData;
	}

	async getGraphData(body, auth){
		const responseData = await this.execute(`post`, `customer/graphData`, body, auth);
		return responseData;
	}

	async getGraphData2(body, auth){
		const responseData = await this.execute(`post`, `customer/graphData2`, body, auth);
		return responseData;
	}

	async sendInvite(body, auth){
		const responseData = await this.execute(`post`, `customer/sendInvite`, body, auth);
		return responseData;
	}

	async acceptInvite(body, auth){
		const responseData = await this.execute(`post`, `customer/acceptInvite`, body, auth);
		return responseData;
	}

	async addTeam(body, auth){
		const responseData = await this.execute(`post`, `customer/addTeam`, body, auth);
		return responseData;
	}

	async deleteTeam(body, auth){
		const responseData = await this.execute(`post`, `customer/deleteTeam`, body, auth);
		return responseData;
	}

	async getTeam(body, auth){
		const responseData = await this.execute(`post`, `customer/getTeam`, body, auth);
		return responseData;
	}

	async getTeam2(body, auth){
		const responseData = await this.execute(`post`, `customer/getTeam2`, body, auth);
		return responseData;
	}

	async integrateGoogle(body, auth){
		const responseData = await this.execute(`post`, `integrations/integrateGoogle`, body, auth);
		return responseData;
	}

	async isGoogleAuthenticated(body, auth){
		const responseData = await this.execute(`post`, `integrations/isAuthenticated`, body, auth);
		return responseData;
	}

	async isShopifyAuthenticated(body, auth){
		const responseData = await this.execute(`post`, `integrations/isShopifyAuth`, body, auth);
		return responseData;
	}

	async getKeys(body, auth){
		const responseData = await this.execute(`post`, `customer/getKeys`, body, auth);
		return responseData;
	}

	async getGorgiasKeys(body, auth){
		const responseData = await this.execute(`post`, `customer/getGorgiasKeys`, body, auth);
		return responseData;
	}

	async sendApiKeys(body, auth){
		const responseData = await this.execute(`post`, `customer/sendApiKeys`, body, auth);
		return responseData;
	}

	async gorgiasKeys(body, auth){
		const responseData = await this.execute(`post`, `customer/gorgiasKeys`, body, auth);
		return responseData;
	}

	
	async trackingKey(body, auth){
		const responseData = await this.execute(`post`, `customer/trackingKey`, body, auth);
		return responseData;
	}

	
	async getTrackingKey(body, auth){
		const responseData = await this.execute(`post`, `customer/getTrackingKey`, body, auth);
		return responseData;
	}

	
	async getAnalytics(body, auth){
		const responseData = await this.execute(`post`, `customer/getAnalytics`, body, auth);
		return responseData;
	}

	async createAccount(body, auth){
		const responseData = await this.execute(`post`, `customer/create`, body, auth);
		return responseData;
	}


	// Set current brand
	async setBrand(body, auth){
		const responseData = await this.execute(`post`, `customer/setBrand`, body, auth);
		return responseData;
	}

	
	async createBrand(body, auth){
		const responseData = await this.execute(`post`, `customer/createBrand`, body, auth);
		return responseData;
	}

	// Get current brand
	async getBrand(body, auth){
		const responseData = await this.execute(`post`, `customer/getBrand`, body, auth);
		return responseData;
	}

	// Get current brand
	async setUsersBrands(body, auth){
		const responseData = await this.execute(`post`, `customer/setUsersBrands`, body, auth);
		return responseData;
	}

	// Get current brand
	async getUsersBrands(body, auth){
		const responseData = await this.execute(`post`, `customer/getUsersBrands`, body, auth);
		return responseData;
	}

	// Set user data from onboarding
	async setUser(body, auth){
		const responseData = await this.execute(`post`, `customer/setUser`, body, auth);
		return responseData;
	}

	
	async getUser(body, auth){
		const responseData = await this.execute(`post`, `customer/getUser`, body, auth);
		return responseData;
	}

	async setWorkspace(body, auth){
		const responseData = await this.execute(`post`, `customer/setWorkspace`, body, auth);
		return responseData;
	}

	async sendSlackMessage(body, auth){
		const responseData = await this.execute(`post`, `customer/sendSlackMessage`, body, auth);
		return responseData;
	}

	async setShopifyKeys(body, auth){
		const responseData = await this.execute(`post`, `customer/setShopifyKeys`, body, auth);
		return responseData;
	}

	async isNylas(body, auth){
		const responseData = await this.execute(`post`, `customer/isNylas`, body, auth);
		return responseData;
	}
	
	async isShopify(body, auth){
		const responseData = await this.execute(`post`, `customer/isShopify`, body, auth);
		return responseData;
	}

	async brandValues(body, auth){
		const responseData = await this.execute(`post`, `customer/brandValues`, body, auth);
		return responseData;
	}

	async stripeUsage(body, auth){
		const responseData = await this.execute(`post`, `stripe/usage`, body, auth);
		return responseData;
	}
	
	async getStripeLink(body, auth){
		const responseData = await this.execute(`post`, `stripe/link`, body, auth);
		return responseData;
	}

	async stripeSubscribe(body, auth){
		const responseData = await this.execute(`post`, `stripe/subscribe`, body, auth);
		return responseData;
	}

	async stripeAdd(body, auth){
		const responseData = await this.execute(`post`, `stripe/add`, body, auth);
		return responseData;
	}

	async stripeAccount(body, auth){
		const responseData = await this.execute(`post`, `stripe/account`, body, auth);
		return responseData;
	}

	async setBrandValues(body, auth){
		const responseData = await this.execute(`post`, `customer/setBrandValues`, body, auth);
		return responseData;
	}

	async nylasEmail(body, auth){
		const responseData = await this.execute(`post`, `nylas/getAddress`, body, auth);
		return responseData;
	}

	
	async setNylas(body, auth){
		const responseData = await this.execute(`post`, `nylas/setNylas`, body, auth);
		return responseData;
	}

	async addIntegration(body, auth){
		const responseData = await this.execute(`post`, `customer/addIntegration`, body, auth);
		return responseData;
	}

	async getIntegrations(body, auth){
		const responseData = await this.execute(`post`, `customer/getIntegrations`, body, auth);
		return responseData;
	}

	async saveIntegration(body, auth){
		const responseData = await this.execute(`post`, `customer/saveIntegration`, body, auth);
		return responseData;
	}

	async deleteIntegration(body, auth){
		const responseData = await this.execute(`post`, `customer/deleteIntegration`, body, auth);
		return responseData;
	}

	async getApi(body, auth){
		const responseData = await this.execute(`post`, `customer/getApi`, body, auth);
		return responseData;
	}

	async resetApi(body, auth){
		const responseData = await this.execute(`post`, `customer/resetApi`, body, auth);
		return responseData;
	}

	async addBrand(body, auth){
		const responseData = await this.execute(`post`, `customer/addBrand`, body, auth);
		return responseData;
	}

	
	async createApi(body, auth){
		const responseData = await this.execute(`post`, `customer/createApi`, body, auth);
		return responseData;
	}

	async addClient(body, auth){
		const responseData = await this.execute(`post`, `customer/addClient`, body, auth);
		return responseData;
	}

	async getClients(body, auth){
		const responseData = await this.execute(`post`, `customer/getClients`, body, auth);
		return responseData;
	}

	async deleteClient(body, auth){
		const responseData = await this.execute(`post`, `customer/deleteClient`, body, auth);
		return responseData;
	}

    async execute(method, path, data, auth) {
		// console.log("Called execute with the baseURL set as ", this.baseUrl)
		// console.log(`${method}, ${path}`);
		// if (data)
			// console.log(data);
		const headers = {
			'content-type': 'application/json',
		};
		if (auth?.id) {
			headers['Authorization'] = `Bearer ${auth?.id}`;
		}
		if (auth?.apiKey) {
			headers['X-API-Key'] = auth?.apiKey;
		}
		const response = await axios({
			method: method,
			baseURL: this.baseUrl,
			url: path,
			headers: headers,
			data: data
		});
		// console.log(response.status);
		if (response.status >= 400)
			throw Error(response.data);

		// console.log(response.data);
		return response.data;
	}
}

const apiClient = new ApiClient();
export default apiClient;