import firebase from "firebase/app"
import "firebase/auth"

// Your web app's Firebase configuration
const app = firebase.initializeApp({
    apiKey: "AIzaSyAJhlYmhMEhtgqk-78h6OCOh7noeylNS8k",
    authDomain: "aics-1d324.firebaseapp.com",
    projectId: "aics-1d324",
    storageBucket: "aics-1d324.appspot.com",
    messagingSenderId: "48564313332",
    appId: "1:48564313332:web:c8ec888199b8b377ff12a1"
});

export const auth = app.auth()
export default app