import { ConfigProvider } from 'antd';
import React, { useEffect, useState } from 'react';

export const ThemeProvider = ({ children }) => {

  const [theme, setTheme] = useState({});

  useEffect(() => {
    const cur = localStorage.getItem('theme');
    let newTheme = {};
    
    if (cur !== 'light') {
      newTheme = {
        token: {
          colorPrimary: '#8284FA',
          colorBgBase: '#191919',
          colorTextBase: '#F2F2F2',
          controlItemBgActive: '#0D0D0D',
          controlItemBgActiveHover: '#121212',
        },
      };
    }

    setTheme(newTheme);

    if (newTheme.token && Object.keys(newTheme.token).length > 0) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  return (
    <ConfigProvider theme={theme}>
      {children}
    </ConfigProvider>
  )
}