import { Switch, Route } from "react-router-dom";
import Login from "./component/Login";
import Menu from "./component/Menu"
import PrivateRoute from "./component/PrivateRoute";
import Refund from "./component/Refund";
import EmailView from "./component/EmailView";
import Provider from "./context/Provider";
import Dashboard from "./pages/Dashboard";
import Analytics from "./pages/Analytics";
import NotificationDashboard from "./pages/NotificationDashboard";
import Settings from "./pages/Settings";
import Invite from "./component/Invite";
import Test from "./component/Test";
import Popup from "./component/Popup";
import TableContainer from "./component/TableContainer";
import Dashboardd from "./component/Dashboard/Dashboard";
import Dashboard2 from "./component/Dashboard2";
import Integration from "./pages/Integration";
import Finetune from "./pages/Finetune";
import Onboarding from "./component/Onboarding";
import Message from "./component/Message";
import Privacy from "./component/Privacy/Privacy";

function App() {
  return (
      <Provider>
        <Switch>
          {/* <PrivateRoute exact path="/" component={TableContainer} />  */}
          {/* <PrivateRoute exact path="/i" component={Integration} /> */}
          <PrivateRoute exact path="/finetune" component={Finetune} />
          <Route path="/invite" component={Invite} exact/>
          <PrivateRoute exact path="/e/:id" component={Popup} />
          <PrivateRoute exact path="/a" component={Analytics} />
          <PrivateRoute exact path="/" component={Settings} />
          <PrivateRoute exact path="/s" component={Settings} />
          <PrivateRoute path="/e/:id" component={EmailView} />
          <Route path="/login" component={Login} />
          <Route path="/menu" component={Menu} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/refund/:id/:order" component={Refund} />
        </Switch>
      </Provider>
  );
}

export default App;