import React from 'react'
import styles from './refund.module.css';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import { Button, Space } from 'antd';
import { useCustomer } from '../../context/CustomerProvider';


const Refund = () => {
    // const [order, setVariable] = useState({})

    const { shopifyOrder, getShopifyRefund, loading } = useCustomer()
    
    let { id, order } = useParams();

    useEffect(() => {
      console.log("id, order", id, order)
      getShopifyRefund(id, order)
    }, [])


    //call api function from api client
    const handleRefund = async () => {
        try {
          //
        } catch {
          //
        }
      }

    return (
        <div className={styles.container}>
          { !loading && shopifyOrder ?
            <>
            <h2>{shopifyOrder.brandName} Refund {shopifyOrder.orderNumber}</h2>
            <br></br>
            <p>Hi {shopifyOrder.firstName},<p>
            <br></br>
            </p>Customer satisfaction is at the heart of everything we do and we want to make sure you're more than satisfied. We are doing our absolute best to 
            get your product to you, but if you're unsatisfied you can refund your order in 1-click with this link.</p>
            <br></br>
            <p>If you plan to wait it out we appreciate your trust and patience, we'll do our best not to let you down!</p>
            <br></br>
            <p className={styles.gray}>Please note: After you refund you cannot undo this action. Funds may take 3-5 days before re-entering your account.</p>
            <br></br>
            <Button type="primary" onClick={() => handleRefund()}>Refund</Button>
            </>
            :
            <h1>Please wait</h1>
          }
        </div>
    )
}

export default Refund